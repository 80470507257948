import BellingcatLogo from "@/styles/image/clothoff-landing/logos/bellingcat.webp";
import BlockChainNewsLogo from "@/styles/image/clothoff-landing/logos/blockchain-news.webp";
import CbsNewsLogo from "@/styles/image/clothoff-landing/logos/cbs-news.webp";
import ElConfidencialLogo from "@/styles/image/clothoff-landing/logos/el-confidencial.webp";
import ElCorreoLogo from "@/styles/image/clothoff-landing/logos/el-correo.webp";
import ElEspanolLogo from "@/styles/image/clothoff-landing/logos/el-espanol.webp";
import ElindependienteLogo from "@/styles/image/clothoff-landing/logos/el-independiente.webp";
import GigazineLogo from "@/styles/image/clothoff-landing/logos/gigazine.webp";
import HuffpostLogo from "@/styles/image/clothoff-landing/logos/huffpost.webp";
import InsiderSpainLogo from "@/styles/image/clothoff-landing/logos/insider-spain.webp";
import MediatizeLogo from "@/styles/image/clothoff-landing/logos/mediatize.webp";
import MessageroLogo from "@/styles/image/clothoff-landing/logos/messaggero.webp";
import MLYearningLogo from "@/styles/image/clothoff-landing/logos/ml-yearning.webp";
import NewsBytesLogo from "@/styles/image/clothoff-landing/logos/news-bytes.webp";
import PowerUserLogo from "@/styles/image/clothoff-landing/logos/power-user.webp";
import SitejabberLogo from "@/styles/image/clothoff-landing/logos/sitejabber.webp";
import TheGuardianLogo from "@/styles/image/clothoff-landing/logos/the-guardian.webp";
import ToolPilotLogo from "@/styles/image/clothoff-landing/logos/toolpilot.webp";

export const LANDING_ROOT_ID = "landing-root";

export const ANCHORS = {
  howItWorks: "howItWorks",
  articles: "articles",
  faq: "faq",
  about: "about",
};

export const LOGO_ITEMS = [
  TheGuardianLogo,
  BellingcatLogo,
  BlockChainNewsLogo,
  CbsNewsLogo,
  ElConfidencialLogo,
  ElEspanolLogo,
  ElCorreoLogo,
  ElindependienteLogo,
  GigazineLogo,
  HuffpostLogo,
  InsiderSpainLogo,
  MediatizeLogo,
  MessageroLogo,
  MLYearningLogo,
  NewsBytesLogo,
  PowerUserLogo,
  SitejabberLogo,
  ToolPilotLogo,
];

export const DAY_IN_SEC = 60 * 60 * 24 * 1;
