"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { usePortalContext } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import {
  Dispatch,
  FC,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useGTMContext, useHeadersContext } from "@/providers";
import { cn } from "@/utils/cn";

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string }>;
}
type TPwaButtonProps = HTMLAttributes<HTMLDivElement> & {
  setIsVisibleAction: Dispatch<SetStateAction<boolean>>;
};

export const PwaButton: FC<TPwaButtonProps> = ({
  setIsVisibleAction,
  className,
  ...props
}) => {
  const t = useTranslations("InstallAppModal");
  const { sendGTM } = useGTMContext();
  const { openPortal } = usePortalContext();
  const { ["user-agent"]: userAgent } = useHeadersContext();

  const installIcon = useMemo(() => {
    switch (true) {
      case /Android|iPhone|iPad|iPod/i.test(userAgent):
        return "install_mobile";
      default:
        return "install_desktop";
    }
  }, [userAgent]);

  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  const promptAppInstall = async () => {
    if (!deferredPrompt) {
      sendGTM({ event: GE.CLICK_INSTALL_PWA__POPUP });
      openPortal(PORTALS.PWA);
    } else {
      sendGTM({ event: GE.CLICK_INSTALL_PWA__NATIVE });
      await deferredPrompt.prompt();
      await deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("user accepted A2HS prompt");
          setIsVisibleAction(false);
        } else {
          console.log("user dismissed A2HS prompt");
        }
      });
      setDeferredPrompt(null);
    }
  };

  const handleBeforeInstallPrompt = (e: Event) => {
    e.preventDefault();
    setDeferredPrompt(e as BeforeInstallPromptEvent);
  };

  useEffect(() => {
    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener,
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener,
      );
    };
  }, []);

  return (
    <div
      id="divInstallApp"
      className={cn(
        "relative flex w-auto items-center justify-center gap-2",
        "cursor-pointer bg-regular p-1.5 duration-200",
        "text-base font-medium text-foreground",
        "lg:m-0 lg:min-h-16 lg:rounded-lg",
        "hover:inset-ring-2 hover:inset-ring-regular-foreground/10",
        "active:scale-97 active:duration-100",
        className,
      )}
      onClick={promptAppInstall}
      {...props}
    >
      {t("installApp")}
      <span className="material-icons" data-icon={installIcon} />
    </div>
  );
};
