"use client";

import { Switch } from "@cloai/uikit";
import { FC, memo } from "react";

import { Gender } from "@/.gql/graphql";
import { useProfileContext, useUndressContext } from "@/providers";
import { cn } from "@/utils/cn";

import { useClickSettings } from "./useClickSettings";

/**
 * Классы для иконок пола.
 */
const ICON_CLASSLIST =
  "material-icons !text-base font-normal cursor-pointer leading-none opacity-48 duration-200";

/**
 * Переключатель пола (Male / Female).
 * @returns Компонент `GenderSwitch`
 */
export const GenderSwitch: FC = memo(() => {
  const profile = useProfileContext();
  const { clickSettings } = useUndressContext();
  const { gender } = clickSettings;

  const handleItemClick = useClickSettings();

  if (!profile) {
    return null;
  }

  return (
    <div
      className={cn(
        "flex items-center gap-2 p-3",
        "rounded-lg inset-ring-2 inset-ring-regular",
      )}
    >
      {/* Кнопка выбора Female */}
      <span
        className={cn(ICON_CLASSLIST, {
          "opacity-100": gender === Gender.Famale,
        })}
        data-icon="female"
        onClick={handleItemClick("gender", Gender.Famale)}
      />

      {/* Переключатель пола */}
      <Switch
        checked={gender === Gender.Male}
        onClick={handleItemClick(
          "gender",
          gender === Gender.Famale ? Gender.Male : Gender.Famale,
        )}
        size="small"
      />

      {/* Кнопка выбора Male */}
      <span
        className={cn(ICON_CLASSLIST, {
          "opacity-100": gender === Gender.Male,
        })}
        data-icon="male"
        onClick={handleItemClick("gender", Gender.Male)}
      />
    </div>
  );
});

GenderSwitch.displayName = "GenderSwitch";
