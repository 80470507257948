"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_BUY } from ".constants/links";
import { Offer } from "@cloai/uikit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslations } from "next-intl";

import BgLg from "@/styles/image/offers/fools-day.webp";

import { useClickOffer } from "./useClickOffer";

dayjs.extend(utc);

export const BuyVipCoins = () => {
  const t = useTranslations("BuyVipCoinsOffer");
  const n = useTranslations("TimerForOffer");

  const handleClickOffer = useClickOffer();

  const endTime = dayjs().endOf("day").add(3, "days").toDate();
  // const endTime = dayjs("2025-03-31T10:00:00+03:00").toDate();

  return (
    <Offer
      image={BgLg}
      theme="covered"
      title={<span className="text-black">{t("title")}</span>}
      // text={t("text")}
      days={n("days")}
      hours={n("hours")}
      minutes={n("minutes")}
      seconds={n("seconds")}
      endDate={endTime}
      href={PATH_BUY}
      onClick={handleClickOffer(GE.BANNER_BUY_VIP_COIN)}
      timerColor="orange"
    />
  );
};
