"use client";

import { PORTALS } from ".constants/portals";
import { usePortalContext } from "@cloai/uikit";
import { toInteger } from "lodash";
import { useLayoutEffect } from "react";
import { useCookies } from "react-cookie";

import { useHeadersContext } from "@/providers";

export const ClothLandingRulesBlock = () => {
  const { openPortal, currentPortal } = usePortalContext();

  const [cookies] = useCookies(["rulesAccepted"]);

  const { rulesaccepted: rulesAccepted } = useHeadersContext();

  const isRulesAccepted =
    toInteger(cookies.rulesAccepted ?? rulesAccepted) === 1;

  useLayoutEffect(() => {
    if (!isRulesAccepted && currentPortal !== PORTALS.Rules) {
      openPortal(PORTALS.Rules);
    }
  }, [currentPortal, isRulesAccepted, openPortal]);

  return null;
};
