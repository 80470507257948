"use client";

import { usePortalContext } from "@cloai/uikit";
import AutoScroll from "embla-carousel-auto-scroll";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/image";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

import { cn } from "@/utils/cn";

import { LOGO_ITEMS } from "../const";

type LogosAutoSlideProps = {
  className?: string;
};

export const LogosAutoSlide = ({ className }: LogosAutoSlideProps) => {
  const { currentPortal } = usePortalContext();
  const [cookies] = useCookies(["rulesAccepted"]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: "start",
      dragFree: true,
      containScroll: false,
    },
    [AutoScroll({ playOnInit: false })],
  );

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) {
      return;
    }

    const isActive = +cookies.rulesAccepted === 1 && !currentPortal;

    if (isActive) {
      autoScroll.play();
    } else {
      autoScroll.stop();
    }
  }, [currentPortal, cookies, emblaApi]);

  return (
    <div
      className={cn(
        "pointer-events-none relative z-2 flex w-full justify-start overflow-hidden py-4 select-none",
        "xl:py-12",
        className,
      )}
    >
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex touch-pan-y">
          {LOGO_ITEMS.map((logo, index) => (
            <Image
              key={index}
              src={logo}
              alt=""
              priority
              className={cn(
                "h-8 w-auto max-w-32 shrink-0 grow-0 basis-auto transform-gpu object-contain pl-6",
                "md:h-12 md:max-w-60 md:pl-10",
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );

  // (
  // <AutoSlider className={className}>
  //   {LOGO_ITEMS.map((logo, index) => (
  //     <Image
  //       key={index}
  //       src={logo}
  //       alt=""
  //       priority
  //       className={cn("h-8 w-auto", "md:h-12")}
  //     />
  //   ))}
  // </AutoSlider>
  // );
};
