"use client";

import { ClothFilterBlock, ClothFilterSet } from "@cloai/uikit";
import { isEmpty } from "lodash";
import { useTranslations } from "next-intl";
import { FC } from "react";

import {
  type AiRequestsProps,
  type PhotoSettings,
  useUndressContext,
  useUndressSettingsContext,
} from "@/providers";

import { UIListWrapper } from "./UIListWrapper";
import { useRenderItem } from "./useRenderItem";

export const CustomizeSettingsList: FC = () => {
  const t = useTranslations("HomePage.settingsPopup");
  const { initialSettings, clickSettings } = useUndressContext();
  const {
    ages,
    bodys,
    busts,
    butts,
    environments,
    hairColors,
    qualities,
    skinColors,
    styles,
  } = useUndressSettingsContext();

  const { renderFilterItems, noSelectedItem } = useRenderItem();

  const {
    age,
    bodyType,
    breastSize,
    buttSize,
    environment,
    hairColor,
    quality,
    set,
    skinColor,
    style,
  } = clickSettings;

  /**
   * Рендерит секцию настроек.
   * @param index - Индекс секции
   * @param items - Данные для рендера
   * @param selectedItem - Текущее выбранное значение
   * @param itemType - Тип настройки (`costume`, `pose`, `set`)
   * @param extraItems - Дополнительные элементы (например, `noSelectedItem`)
   */
  const renderSection = (
    title: string,
    items: AiRequestsProps,
    selectedItem: string | number,
    itemType: keyof PhotoSettings,
    extraItems: AiRequestsProps = [],
  ) =>
    !isEmpty(items) && (
      <ClothFilterBlock title={title}>
        <ClothFilterSet>
          {renderFilterItems([...extraItems, ...items], selectedItem, itemType)}
        </ClothFilterSet>
      </ClothFilterBlock>
    );

  return (
    <UIListWrapper className="-mt-indent pt-indent lg:h-85">
      <div className="scroll-mask-y-4 scrollbar-y-2 flex flex-col gap-indent overflow-x-hidden">
        {set !== initialSettings.set && (
          <>
            {renderSection(t("hairColor"), hairColors, hairColor, "hairColor", [
              noSelectedItem(t("hairColorDefault"), 1),
            ])}
            {renderSection(t("skinColor"), skinColors, skinColor, "skinColor", [
              noSelectedItem(t("hairColorDefault"), 1),
            ])}
            {renderSection(
              t("environment"),
              environments,
              environment,
              "environment",
              [noSelectedItem(t("environmentDefault"), 1)],
            )}
          </>
        )}

        {renderSection(t("bustSize"), busts, breastSize, "breastSize")}
        {renderSection(t("buttSize"), butts, buttSize, "buttSize")}
        {renderSection(t("quality"), qualities, quality, "quality")}
        {renderSection(t("style"), styles, style, "style")}
        {renderSection(t("age"), ages, age, "age")}
        {renderSection(t("bodyType"), bodys, bodyType, "bodyType")}
      </div>
    </UIListWrapper>
  );
};
