import { GTMEvent } from ".constants/gtmEvents";
import { usePortalContext } from "@cloai/uikit";

import { useGTMContext } from "@/providers";

export const useClickOffer = () => {
  const { sendGTM } = useGTMContext();
  const { openPortal } = usePortalContext();
  const handleClickOffer = (event: GTMEvent, portal?: string) => () => {
    sendGTM({ event });
    portal && openPortal(portal);
  };

  return handleClickOffer;
};
