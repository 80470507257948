import {
  PATH_ABOUT_US,
  PATH_ANDROID,
  PATH_BLOG,
  PATH_COMPLAINT_POLICY,
  PATH_CONTACT_US,
  PATH_DEVELOPER,
  PATH_FAQ,
  PATH_IOS,
  PATH_MAKE_MONEY,
  PATH_PRELAND_AI_CLOTHES_REMOVER,
  PATH_PRELAND_DEEPNUDE,
  PATH_PRELAND_DEEPSUKEBE,
  PATH_PRELAND_MUKE_AI,
  PATH_PRELAND_NUDE_AI,
  PATH_PRELAND_NUDIFY,
  PATH_PRELAND_UDRESS_AI,
  PATH_PRESS,
  PATH_PRIVACY,
  PATH_PROCESSING_PRIVACY,
  PATH_REFUND,
  PATH_TERMS,
  PATH_TG_BOT,
  PATH_USE_POLICY,
} from ".constants/links";
import { EC_COUNTRIES_LIST } from ".constants/texts";
import { useTranslations } from "next-intl";
import { SyntheticEvent } from "react";

import { useCompany } from "@/components/hooks/useCompany";
import { useSupport } from "@/components/hooks/useSupport";
import { useHeadersContext } from "@/providers";
import { getGTM } from "@/utils/gtm";
import { getCountry } from "@/utils/headers";

type CommonLink = {
  hide?: boolean;
  text: string;
  href: string | null;
  onClick?: (e: SyntheticEvent) => void;
};

type PrelandLink = {
  text: string;
  href: string;
};

type FooterLinks = {
  prelands: PrelandLink[];
  common: CommonLink[];
};

export const useFooterLinks = (): FooterLinks => {
  const f = useTranslations("Footer");
  const s = useTranslations("SigninPage");
  const { companyLower } = useCompany();
  const headers = useHeadersContext();
  const onClickSupport = useSupport();

  const GTM = getGTM(companyLower);
  const country = getCountry(headers);

  return {
    prelands: [
      {
        href: PATH_PRELAND_UDRESS_AI,
        text: f("aiTools.undressAi"),
      },
      {
        href: PATH_PRELAND_DEEPNUDE,
        text: f("aiTools.deepnudeAi"),
      },
      {
        href: PATH_PRELAND_NUDIFY,
        text: f("aiTools.nudifyAi"),
      },
      {
        href: PATH_PRELAND_MUKE_AI,
        text: f("aiTools.mukeAi"),
      },
      {
        href: PATH_PRELAND_NUDE_AI,
        text: f("aiTools.nudeAi"),
      },
      {
        href: PATH_PRELAND_AI_CLOTHES_REMOVER,
        text: f("aiTools.aiClothesRemover"),
      },
      {
        href: PATH_PRELAND_DEEPSUKEBE,
        text: f("aiTools.deepsukebeAi"),
      },
    ],
    common: [
      {
        href: PATH_MAKE_MONEY,
        text: f("makeMoney"),
      },
      {
        href: PATH_ABOUT_US,
        text: f("about_us"),
      },
      {
        href: PATH_REFUND,
        text: f("refund"),
      },
      {
        href: PATH_PRESS,
        text: f("smi"),
      },
      {
        href: PATH_BLOG,
        text: f("blog"),
      },
      {
        href: PATH_DEVELOPER,
        text: f("tryAPI"),
      },
      {
        href: PATH_PRIVACY,
        text: f("privacyPolicy"),
      },
      {
        href: PATH_COMPLAINT_POLICY,
        text: f("complaintPolicy"),
      },
      {
        href: PATH_PROCESSING_PRIVACY,
        text: f("dataProcessingPolicy"),
        hide: !EC_COUNTRIES_LIST.includes(country),
      },
      {
        href: PATH_TERMS,
        text: f("termsofService"),
      },
      {
        href: PATH_USE_POLICY,
        text: f("usePolicy"),
      },
      {
        href: PATH_TG_BOT,
        text: s("telegramBot"),
      },
      {
        href: PATH_ANDROID,
        text: `${f("getAppOn")} Android`,
      },
      {
        href: PATH_IOS,
        text: `${f("getAppOn")} Apple IOS`,
      },
      {
        href: PATH_FAQ,
        text: f("faq"),
      },
      {
        hide: !GTM,
        href: null,
        text: f("support"),
        onClick: onClickSupport,
      },
      {
        href: PATH_CONTACT_US,
        text: f("contactUs"),
      },
    ],
  };
};
