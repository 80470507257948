export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      typeof reader.result === "string"
        ? resolve(reader.result)
        : reject("Failed to read file as base64");
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
