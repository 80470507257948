"use client";

import { Button, PlateContent, Text } from "@cloai/uikit";

import { useProfileContext, useUndressContext } from "@/providers";

export const AdminResetUndressButton = () => {
  const profile = useProfileContext();
  const context = useUndressContext();

  if (!profile?.isAdmin || !context) {
    return null;
  }

  const { onReset, loading, undressings } = context;

  return (
    <PlateContent gap={12} theme="lightblue-flat">
      <Text
        text={"This block is only available to admins"}
        color="orange"
        center
      />
      <Button
        iconLeft="delete"
        text="Reset Undress"
        color="red"
        onClick={onReset}
        loading={loading}
        disabled={!undressings}
        centerText
        wide
      />
    </PlateContent>
  );
};
