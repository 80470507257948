import clsx from "clsx";
import Image from "next/image";
import { FC, memo } from "react";

import styles from "./Frames.module.scss";

/**
 * Компонент Frames отображает горизонтальный трек кадров (thumbnail) из видео или последовательности изображений.
 * Каждый кадр представляет собой миниатюру, отображаемую с помощью компонента Image.
 *
 * @component
 * @example
 * ```typescript
 * const frames = [
 *   "data:image/jpeg;base64,...",
 *   "data:image/jpeg;base64,...",
 *   ...
 * ];
 *
 * <Frames frames={frames} />
 * ```
 *
 * @param {Object} props Пропсы компонента.
 * @param {string[] | undefined} props.frames Массив строк, каждая из которых — DataURL кадра или URL изображения.
 * Если не передан или пуст, компонент ничего не рендерит.
 *
 * @returns {JSX.Element | null} Возвращает трек кадров в виде списка миниатюр. Если frames не передан, возвращает null.
 */
export const Frames: FC<{ frames: string[] | undefined }> = memo(
  ({ frames }) => {
    // Если массив кадров не передан или пуст, не отображаем ничего.
    if (!frames) {
      return null;
    }

    return (
      <div className={clsx(styles.track)}>
        {frames.map((item, Index) => (
          <div key={Index} className={clsx(styles.frame)}>
            {/**
             * Отображаем кадр как изображение.
             * Используем Next.js Image для оптимизации загрузки.
             * width и height заданы, чтобы избежать скачков при рендере.
             */}
            <Image
              width={64}
              height={50}
              src={item}
              className={clsx(styles.img)}
              alt="Frames"
            />
          </div>
        ))}
      </div>
    );
  },
);
Frames.displayName = "Frames";
