"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_EARN } from ".constants/links";
import {
  Box,
  Button,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PlateContent,
  Subtitle,
  Text,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, memo } from "react";

import { PacksList } from "@/app/[locale]/@modals/(.)buy/PacksList";
import { Undressing, useConfigContext, useGTMContext } from "@/providers";

/**
 * Компонент `PlateCoinPacks` отображает плашку с предложениями купить монеты или услуги,
 * исходя из страны пользователя и его профиля.
 *
 * Поведение:
 * - Плашка отображается через 10 секунд после монтирования (используем `useMount` и `setTimeout`).
 *
 *
 * Если плашка не должна показываться (по логике), возвращает `null`.
 */
export const PlateCoinPacks: FC = memo(() => {
  const p = useTranslations("BuyPage.packagesModal");

  return (
    <PlateContent gap={16} fit theme="darkblue" style={{ maxHeight: "100%" }}>
      <ModalHeader>{p("flowTitle")}</ModalHeader>

      <ModalContent>
        <PacksList />
      </ModalContent>
    </PlateContent>
  );
});
PlateCoinPacks.displayName = "PlateCoinPacks";

/**
 * Компонент `PlateGetFreeCoins` отображает плашку с предложением получить бесплатные монеты,
 * даёт подсказки как получить 10 и 25 монет, и кнопку перехода на страницу `earn` (пригласить друга).
 *
 * При нажатии на кнопку отправляет GTM-событие для аналитики.
 */
export const PlateGetFreeCoins: FC = memo(() => {
  const { sendGTM } = useGTMContext();
  const b = useTranslations("HomePage");
  const t = useTranslations("HomePage.noCoinsPopup.free");
  const { freeCoinsAfterReferring } = useConfigContext();

  const onInviteFriend = () => {
    sendGTM({ event: GE.FLOW_NO_COINS_POPUP_EARN_COINS_BUTTON });
  };

  return (
    <PlateContent gap={16} fit theme="blue" style={{ maxHeight: "100%" }}>
      <ModalContent>
        <Subtitle text="😟" fontSize={64} center />

        <Subtitle text={t("title")} color="orange" center />

        <ul>
          <Box gap={16}>
            <Text Component="li" text={t("clause1", { coins: 10 })} />
            <Text
              Component="li"
              text={t.rich("clause2", { coins: freeCoinsAfterReferring })}
            />
          </Box>
        </ul>

        <ModalFooter>
          <Button
            href={PATH_EARN}
            iconLeft="person_add"
            text={b("inviteFriend")}
            color="violet"
            wide
            centerText
            onClick={onInviteFriend}
          />
        </ModalFooter>
      </ModalContent>
    </PlateContent>
  );
});
PlateGetFreeCoins.displayName = "PlateGetFreeCoins";

/**
 * Компонент `PlateError` отображает плашку с ошибкой при обработке фото.
 *
 * Если `status=2` (обработка не удалась), показывает иконку, сообщение об ошибке,
 * и текст ошибки из `undress?.error`.
 *
 * @param {object} props Пропсы компонента.
 * @param {Undressing | null | undefined} props.undress Объект undress, содержащий возможную ошибку.
 */
export const PlateError: FC<{ undress: Undressing | null | undefined }> = memo(
  ({ undress }) => {
    const t = useTranslations("HomePage.statusProcess");

    return (
      <div className="flex size-full flex-col items-center justify-center gap-4 p-container text-center">
        <span className="text-3xl">🚫</span>

        <span className="text-xl font-semibold">
          {t("errorPhotoIsNotProcessed")}
        </span>

        {/**
         * Отображаем сообщение об ошибке.
         * `t(undress?.error as never)` — предполагается, что ключ ошибки есть в переводах.
         */}
        <span className="font-medium text-foreground/50">
          {t(undress?.error as never)}
        </span>
      </div>
    );
  },
);
PlateError.displayName = "PlateError";
