"use client";

import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { map } from "lodash";

import { useUndressContext } from "@/providers";

import { useCompany } from "../hooks/useCompany";
import { BuyVipCoins } from "./BuyVipCoins";
import { NewCollection } from "./NewCollection";
import { NewFeatures } from "./NewFeatures";

export const OffersCarousel = () => {
  const { isActive } = useUndressContext();
  const { isOkbra } = useCompany();

  const [emblaRef] = useEmblaCarousel({ loop: true, dragFree: false }, [
    Autoplay({
      delay: 7000,
      active: true,
    }),
  ]);

  const offerList = [
    <BuyVipCoins key="BuyVipCoins" />,
    <NewFeatures key="NewFeatures" />,
    <NewCollection key="NewCollection" />,
  ];

  if (isOkbra) {
    return null;
  }

  if (!isActive) {
    return null;
  }

  return (
    <div className="w-full" ref={emblaRef}>
      <div className="will-change-transformt -ml-container flex w-auto touch-pan-y">
        {map(offerList, (offer, index) => (
          <div
            key={index}
            className="shrink-0 grow-0 basis-full pl-container will-change-transform"
          >
            {offer}
          </div>
        ))}
      </div>
    </div>
  );
};
