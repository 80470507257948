import { Ref as ReactRef, RefObject, useEffect, useState } from "react";

type SliderValue = [number, number];

interface UseRangeLogicParams {
  /**
   * Текущее значение диапазона слайдера: [startTime, endTime].
   * startTime - начальное время диапазона, endTime - конечное время.
   */
  value: SliderValue;

  /**
   * Ссылка на элемент <video>, для синхронизации и управления его воспроизведением.
   */
  videoRef: ReactRef<HTMLVideoElement>;
}

/**
 * Кастомный хук для управления логикой воспроизведения видео в заданном диапазоне.
 *
 * Данный хук:
 * - Отслеживает текущее время воспроизведения видео (`playLineTimelie`).
 * - Позволяет маркировать состояние "перетаскивания" ползунка (`isDraging`), чтобы при достижении конца диапазона
 *   (value[1]) воспроизведение остановить и сразу перемотать на начало диапазона (value[0]) при условии,
 *   что пользователь не перетаскивает сейчас ползунок.
 * - Устанавливает слушатель события `timeupdate` на видео, чтобы отслеживать текущее время воспроизведения,
 *   и при необходимости останавливать и перематывать его.
 *
 * Пример использования:
 * ```typescript
 * const { playLineTimelie, isDraging, onDraging } = usePlayLogic({ value, videoRef });
 *
 * // playLineTimelie - текущее время воспроизведения (с точностью до 2 знаков)
 * // isDraging - флаг, указывающий, что пользователь двигает ползунок
 * // onDraging(true) - функция для установки состояния перетаскивания в true,
 * // onDraging(false) - функция для сброса в false.
 * ```
 *
 * @param {UseRangeLogicParams} params Объект параметров слайдера и видео.
 * @returns Объект { playLineTimelie, isDraging, onDraging } для управления логикой воспроизведения.
 */
export function usePlayLogic({ value, videoRef }: UseRangeLogicParams) {
  // Состояние для отслеживания текущего времени воспроизведения видео (playLineTimeline),
  // округлённого до двух знаков после запятой.
  const [playLineTimelie, setPlayLineTimeline] = useState<number>(0);

  // Состояние, указывающее, находится ли пользователь в процессе перетаскивания ползунка (слайдера).
  const [isDraging, setIsDraging] = useState(false);

  /**
   * Функция onDraging - возвращает колбэк, устанавливающий флаг isDraging.
   * Используйте onDraging(true) при начале перетаскивания и onDraging(false) при завершении.
   * Это позволит хуку понять, что нельзя перематывать видео, пока пользователь держит ползунок.
   */
  const onDraging = (val: boolean) => () => {
    setIsDraging(val);
  };

  // Получаем сам элемент <video> из рефа
  const videoElement = (videoRef as RefObject<HTMLVideoElement>).current;

  useEffect(() => {
    // Если видео ещё не доступно или не проинициализировано, выходим
    if (!videoElement) {
      return;
    }

    /**
     * Обработчик события 'timeupdate', вызываемого при обновлении текущего времени воспроизведения.
     * - Обновляем playLineTimelie текущим временем.
     * - Если текущее время воспроизведения достигло value[1] (конец диапазона) и пользователь
     *   не двигает ползунок (isDraging === false), тогда:
     *   - Ставим видео на паузу
     *   - Перематываем текущее время к value[0]
     *   - Снова запускаем воспроизведение
     */
    const handleTimeUpdate = () => {
      setPlayLineTimeline(Number(videoElement.currentTime.toFixed(2)));

      if (videoElement.currentTime >= value[1] && !isDraging) {
        videoElement.pause();
        // Перематываем к началу диапазона
        // eslint-disable-next-line react-compiler/react-compiler
        videoElement.currentTime = value[0];
        // Запускаем снова
        videoElement.play();
      }
    };

    // Добавляем слушатель события 'timeupdate' к видео
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    // При размонтировании или изменении зависимостей снимаем слушатель
    return () => {
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [isDraging, value, videoElement]);

  return { playLineTimelie, isDraging, onDraging };
}
