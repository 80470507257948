"use client";

import { Icon } from "@cloai/uikit";
import { HTMLAttributes, MouseEvent, ReactNode, useState } from "react";

import { cn } from "@/utils/cn";

const STYLES = {
  base: [
    "relative z-2 cursor-pointer overflow-hidden rounded-xl text-foreground duration-200",
    "hover:opacity-90 hover:inset-ring-1 hover:inset-ring-gray-700",
  ],
  variants: {
    black: "bg-clothoff-landing-secondary",
    slate: "bg-slate-800",
  },
  open: "inset-ring-1 inset-ring-gray-700",
};

type Variant = "black" | "slate";

type AccordionProps = HTMLAttributes<HTMLDivElement> & {
  label: string | ReactNode;
  text: string | ReactNode;
  variant?: Variant;
};

export const Accordion = ({
  text,
  label,
  className,
  onClick,
  variant = "black",
  ...props
}: AccordionProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    setOpen((prev) => !prev);
    onClick?.(e);
  };

  return (
    <div
      className={cn(
        STYLES.base,
        STYLES.variants[variant],
        open && STYLES.open,
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      <div
        className={cn(
          "flex items-center justify-between gap-2 px-6 py-4 transition-padding duration-500 select-none",
        )}
      >
        <div>{label}</div>
        <Icon
          icon="keyboard_arrow_down"
          size={5}
          className={cn(
            "origin-center transition-transform",
            open && "rotate-180",
          )}
        />
      </div>

      <div
        className={cn(
          "relative grid grid-rows-[0fr] px-6 py-0 transition-padding-grid-rows",
          open && "grid-rows-[1fr] pb-4",
        )}
      >
        <div className="min-h-0">
          <div className="text-neutral-300">{text}</div>
        </div>
      </div>
    </div>
  );
};
