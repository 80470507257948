"use client";

// import InstaIcon from "@/styles/image/social-buttons/instagram.svg";
import { GE } from ".constants/gtmEvents";
import { PATH_AUTH, PATH_BUY } from ".constants/links";
import { PORTALS } from ".constants/portals";
import undressConfig from ".constants/undress.config.json";
import {
  Box,
  Button,
  ButtonTag,
  ButtonWrapper,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, SyntheticEvent } from "react";

import { InputUndressType } from "@/.gql/graphql";
import { Status } from "@/components/user-flow/controls/Status";
import { useDropzoneSettings } from "@/components/user-flow/utils/useDropzoneSettings";
import {
  useUndressContext,
  useGTMContext,
  useABtestContext,
  useProfileContext,
} from "@/providers";

import { Position } from "./Position";
import { Rating } from "./Rating";
import { useButtonIcon } from "./useButtonIcon";
import { useButtonText } from "./useButtonText";
import { useClickUndress } from "./useClickUndress";

export const RenderButton: FC<{ token: string | null }> = ({ token }) => {
  const t = useTranslations("HomePage");
  const profile = useProfileContext();
  const { sendGTM, onSendGTM } = useGTMContext();
  const { openPortal } = usePortalContext();
  const {
    EmailTriggerFree,
    EmailTriggerVip,
    AB_anim_group_1_key_0,
    AB_anim_group_1_key_1,
    AB_anim_group_2_key_0,
    AB_anim_group_2_key_1,
    AB_anim_group_2,
  } = useABtestContext();
  const {
    clickSettings,
    clickUndress,
    clickUndressingFile,
    clickUndressStatus,
    initialSettings,
    isOpenCustomizeSettings,
    onReUseFaceObj,
    onReUseFaceMode,
    openCustomizeSettings,
    reUseFaceObj,
    selectedVipSettings,
    undressingCost,
    undressings,
    undressingsFiles,
    videoSettings,
  } = useUndressContext();

  /**
   * Извлекаем настройки дропзоны для фото и видео.
   * - `maxFilesPhoto`: максимальное число фото, которые можно загрузить.
   * - `getRootPhotoProps`, `getInputPhotoProps`: пропсы для дропзоны фото.
   * - `getRootVideoProps`, `getInputVideoProps`: пропсы для дропзоны видео.
   */
  const { maxFilesPhoto, getRootPhotoProps, getInputPhotoProps } =
    useDropzoneSettings();

  // Иконка для кнопки раздевания
  const { undressButtonIcon, reUseFaceDiscountTag } = useButtonIcon();
  const undressButtonText = useButtonText();

  const { set } = clickSettings;

  const {
    isInit,
    isLoading,
    isUndressing,
    isSuccess,
    isError,
    isBlur,
    isEndAllUndress,
  } = clickUndressStatus;

  const disabled =
    (!clickUndressingFile && videoSettings.videoDuration === 0) ||
    isLoading ||
    isBlur;

  /**
   * Функция `onReUseFaceMode` определяет, подписан ли пользователь на email-рассылку,
   * если нет - вызывает попап с предложением оставить своб почту.
   * Далее в любом случае  сбрасывает генерацию.
   */
  const handleReUseFace = async (event: SyntheticEvent) => {
    EmailTriggerFree && openPortal(PORTALS.SubscribesFREE);
    EmailTriggerVip && openPortal(PORTALS.SubscribesVIP);
    await onReUseFaceMode(event);
  };

  const { onSignUndressImage, onSignUndressRemix, onSignUndressVideo } =
    useClickUndress(token);

  const onAnimate = () => {
    sendGTM({ event: GE.FLOW__CLICK_ANIMATE });
    openPortal(PORTALS.AnimatePhoto);
  };

  const onUploadInstagram = () => {
    sendGTM({ event: GE.FLOW_START_CLICK_UPLOAD_INSTAGRAM });
    openPortal(PORTALS.AttachPhotoByInsta);
  };

  /**
   * Функция `renderButton` определяет, какую кнопку отображать,
   * исходя из текущего состояния (гость/авторизованный, монеты, статус undress, демо-режим, процесс загрузки).
   */
  const renderButton = () => {
    switch (true) {
      // Ошибка генерации
      case isError || clickUndress?.error:
        return (
          <Button
            iconLeft="block"
            text={t("statusProcess.errorPhotoIsNotProcessed")}
            color="red"
            wide
            centerText
            disabled
            error
          />
        );

      // Обработка завершена (ГОСТЬ)
      case (isSuccess || isBlur) && clickUndress?.isGuest:
        return (
          <Button
            href={PATH_AUTH}
            iconLeft="key"
            text={t("signUp")}
            wide
            centerText
            onClick={onSendGTM(GE.UNDRESS_GUEST_TO_AUTH)}
            loading={isLoading}
          />
        );

      // Обработка завершена (АВТОРИЗОВАННЫЙ) AB-test V1 GA_AB=0
      case AB_anim_group_1_key_0 &&
        (isSuccess || isBlur) &&
        !clickUndress?.isGuest:
        return (
          <div className="flex w-full justify-center gap-2">
            {clickUndress?.undressType !== InputUndressType.Animation && (
              <>
                <Button
                  text={t("reUseFace")}
                  color="violet"
                  onClick={handleReUseFace}
                  loading={isLoading}
                  disabled={!isEndAllUndress || isBlur}
                  wide
                  centerText
                />
                <Button
                  iconLeft="videocam"
                  color="orange"
                  onClick={onAnimate}
                  loading={isLoading}
                  disabled={!isEndAllUndress || isBlur}
                />
              </>
            )}
            {!AB_anim_group_2 && <Rating />}
          </div>
        );

      // Обработка завершена (АВТОРИЗОВАННЫЙ) AB-test V2 GA_AB=0
      case AB_anim_group_2_key_0 &&
        (isSuccess || isBlur) &&
        !clickUndress?.isGuest:
        return (
          <div className="flex w-full justify-center gap-2">
            {clickUndress?.undressType !== InputUndressType.Animation && (
              <>
                <Button
                  text={t("animate")}
                  color="orange"
                  onClick={onAnimate}
                  loading={isLoading}
                  disabled={!isEndAllUndress || isBlur}
                  wide
                />
                <Button
                  text={t("remix")}
                  color="violet"
                  onClick={handleReUseFace}
                  loading={isLoading}
                  disabled={!isEndAllUndress || isBlur}
                  wide
                />
              </>
            )}
            {!AB_anim_group_2 && <Rating />}
          </div>
        );

      // Обработка завершена (АВТОРИЗОВАННЫЙ) AB-test GA_AB=1
      case (AB_anim_group_1_key_1 || AB_anim_group_2_key_1) &&
        (isSuccess || isBlur) &&
        !clickUndress?.isGuest:
        return (
          <div className="flex w-full justify-center gap-2">
            {clickUndress?.undressType !== InputUndressType.Animation && (
              <>
                <Button
                  text={t("remix")}
                  color="orange"
                  onClick={handleReUseFace}
                  loading={isLoading}
                  disabled={!isEndAllUndress || isBlur}
                  wide
                />
                <Button
                  text={t("animate")}
                  color="violet"
                  onClick={onAnimate}
                  loading={isLoading}
                  disabled={!isEndAllUndress || isBlur}
                  wide
                />
              </>
            )}
            {!AB_anim_group_2 && <Rating />}
          </div>
        );

      // Обработка завершена (АВТОРИЗОВАННЫЙ)
      case (isSuccess || isBlur) && !clickUndress?.isGuest:
        return (
          <div className="flex w-full gap-2">
            <ButtonWrapper wide>
              <Button
                iconLeft="double_arrow"
                text={t("reUseFace")}
                color="orange"
                onClick={handleReUseFace}
                loading={isLoading}
                disabled={!isEndAllUndress || isBlur}
                wide
                centerText
              />
              <ButtonTag text={reUseFaceDiscountTag} color="violet" />
            </ButtonWrapper>
            <Rating />
          </div>
        );

      // Идёт загрузка (`isLoading`)
      case isLoading:
        return (
          <Button
            iconLeft="hourglass_top"
            text={t("processingPhotos")}
            color="passive"
            loading={isLoading}
            wide
            centerText
          />
        );

      // Идёт генерация
      case isUndressing:
        return (
          <Box center wide>
            <Status undress={clickUndress} />
          </Box>
        );

      /**
       * - Закончились коины
       * - стоимость генерации больше 0
       */
      // case noCoinsAndBlur:
      //   return (
      //     <Button
      //       href={PATH_BUY}
      //       scroll={false}
      //       text={t("upgrade")}
      //       color="violet"
      //       wide
      //       onClick={onSendGTM("Flow_No_coins_Buy_vip_coin")}
      //     />
      //   );

      /**
       * Старт флоу если:
       * - загружена фотка
       * - еще НЕ открыты доп.настроки
       * - сэты НЕ выбраны
       * - авторизован
       */
      case isInit &&
        undressingsFiles.length > 0 &&
        !isOpenCustomizeSettings &&
        set === initialSettings.set &&
        !!profile:
        return (
          <Button
            iconLeft="double_arrow"
            text={t("next")}
            centerText
            wide
            disabled={disabled}
            onClick={openCustomizeSettings}
          />
        );

      /**
       * - выбраны VIP-настройки
       * - стоимость генерации больше 0
       * - гость или нехватает VIP-коинов на минимальную генку
       */
      case selectedVipSettings &&
        undressingCost > 0 &&
        (!profile || profile?.coins! < undressConfig.undressCost):
        return (
          <Button
            href={PATH_BUY}
            scroll={false}
            text={t("upgrade")}
            color="violet"
            wide
            onClick={onSendGTM(GE.FLOW_NOT_ENOUGH_COINS_BUY_VIP_COIN)}
          />
        );

      /**
       * Старт флоу если:
       * - загруженая фотка
       * - открыты доп.настройки или пользователь - гость или выбран СЭТ
       */
      case isInit &&
        undressingsFiles.length > 0 &&
        (isOpenCustomizeSettings || !profile || set !== initialSettings.set):
        return (
          <ButtonWrapper wide>
            <Button
              iconLeft="double_arrow"
              text={t("startGenerate")}
              iconRight={profile && undressButtonIcon}
              disabled={disabled}
              onClick={reUseFaceObj ? onSignUndressRemix : onSignUndressImage}
              wide
              centerText
            />
            {reUseFaceObj && !!undressingCost && (
              <ButtonTag text={reUseFaceDiscountTag} color="violet" />
            )}
          </ButtonWrapper>
        );

      // Старт флоу для видео
      case videoSettings.videoDuration > 0:
        return (
          <Button
            iconLeft={!profile ? "checkroom" : ""}
            text={
              <Box gap={0} row center>
                {undressButtonText()}
              </Box>
            }
            disabled={disabled}
            onClick={onSignUndressVideo}
            wide
            centerText
          />
        );

      // Старт флоу: ReUse Face мод
      case isInit && !!reUseFaceObj:
        return (
          <Button
            iconLeft="double_arrow"
            text={t("reUseFace")}
            centerText
            wide
            onClick={onReUseFaceObj}
            disabled={
              !clickSettings.prompt && clickSettings.costume === "prompt"
            }
          />
        );

      // Старт флоу для фото
      case isInit:
        return (
          <div className="flex w-full gap-2">
            <Button
              iconLeft="attach_file"
              text={
                maxFilesPhoto === 1
                  ? t("uploadAPhoto")
                  : t.rich("uploadAPhotosMax", {
                      count: maxFilesPhoto,
                    })
              }
              centerText
              wide
              onClick={onSendGTM(GE.FLOW_START_CLICK_UPLOAD_FILE)}
              disabled={
                !clickSettings.prompt && clickSettings.costume === "prompt"
              }
              {...getRootPhotoProps({})}
            >
              <input {...getInputPhotoProps()} />
            </Button>
            {/* TODO: вернуть когда заработает API сервиса */}
            {/* {profile?.coins! > 0 && (
              <Button
                iconLeft={InstaIcon}
                color="regular"
                onClick={onUploadInstagram}
              />
            )} */}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {renderButton()}
      {
        // Если есть `undressings` и они не все завершены, отображаем `Position` (скрытый `div`),
        // Видимо для расчётов или позиционирования
        !isEndAllUndress && undressings && (
          <div className="hide">
            <Position
              isVideo={clickUndress?.undressType === InputUndressType.Animation}
            />
          </div>
        )
      }
    </>
  );
};
