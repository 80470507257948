"use client";

import { Box, Text } from "@cloai/uikit";
import { map } from "lodash";
import { useTranslations } from "next-intl";
import { FC, memo } from "react";

import { useProfileContext, usePaymentContext } from "@/providers";

import { BuyItemWide } from "./BuyItemWide";

export const PacksList: FC = memo(() => {
  const t = useTranslations("BuyPage");
  const profile = useProfileContext();
  const { userPacksList, dealerPacksList } = usePaymentContext();

  return (
    <>
      <Box gap={4}>
        {profile?.isDealer && <Text text={t("usersPacks")} color="violet" />}
        {map(userPacksList, (coinPack, index) => (
          <BuyItemWide key={index} index={index} coinPack={coinPack} />
        ))}
      </Box>

      {profile?.isDealer && (
        <Box gap={4}>
          <Text text={t("dealerPacks")} color="orange" />
          {map(dealerPacksList, (coinPack, index) => (
            <BuyItemWide key={index} index={index} coinPack={coinPack} />
          ))}
        </Box>
      )}
    </>
  );
});
PacksList.displayName = "PacksList";
