"use client";

import { Turnstile } from "@marsidev/react-turnstile";
import { FC, useState } from "react";

import { AnimatePhotoPortal } from "@/portals";
import { useUndressContext } from "@/providers";
import { cn } from "@/utils/cn";

import { PromtArea } from "./PromtArea";
import { RenderButton } from "./RenderButton";
import { useToggleBg } from "./useToggleBg";

export const Controls: FC = () => {
  const { clickSettings } = useUndressContext();

  const { gender } = clickSettings;
  const { wrapperRef, isBottom } = useToggleBg(gender);

  const [token, setToken] = useState<string | null>(null); // Токен капчи для гостей

  return (
    <div
      className={cn(
        "relative -mx-container flex w-auto flex-col items-center rounded-t-2xl px-container pt-3 transition-colors duration-200",
        "pb-[calc(env(safe-area-inset-bottom)+var(--spacing)*3)]",
        "lg:m-0 lg:p-0",
        isBottom && "bg-regular lg:bg-transparent",
      )}
      ref={wrapperRef}
    >
      {/**
       * Turnstile - капча Cloudflare для гостей.
       * Отображается скрыто, сохраняем токен при успехе onSuccess.
       */}
      <Turnstile
        onSuccess={setToken}
        siteKey={String(process.env.NEXT_PUBLIC_CLOUDFLARE_SITE_KEY)}
        onError={(error) => console.log("error", error)}
        style={{ display: "none" }}
      />

      <PromtArea />
      <RenderButton token={token} />
      <AnimatePhotoPortal token={token} />
    </div>
  );
};
