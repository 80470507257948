"use client";

import { map } from "lodash";
import Image from "next/image";
import { FC, useMemo } from "react";

import { useUndressContext } from "@/providers";
import { cn } from "@/utils/cn";

import { UIWrapper } from "./UIWrapper";

export const UploadedPhotoBlock: FC = () => {
  const {
    clickUndressingFile,
    clickUndressStatus,
    loading,
    undressings,
    undressingsFiles,
  } = useUndressContext(); // Контекст с данными о "раздевании" (`undress`)

  const { isUndressing, isError, isBlur } = clickUndressStatus;

  /**
   * Блюрим фотографию по условиям:
   * - ожидаем ответ сервера
   * - идет обработка
   * - ошибка генерации
   * - гостевой блюр
   * - закончились коины
   * - загрузка псевдогенерации
   */
  const isBluredImg = useMemo(
    () => loading || isUndressing || isError || isBlur,
    [isBlur, isError, isUndressing, loading],
  );

  // Если нет загруженного изображения - ничего не отображаем.
  if (!clickUndressingFile) {
    return null;
  }

  return (
    <UIWrapper>
      <RenderImage
        list={[...(undressings || []), ...(undressingsFiles || [])]}
        selectedId={clickUndressingFile.id}
        isBluredImg={isBluredImg}
        isError={isError}
      />
    </UIWrapper>
  );
};

/**
 * Компонент для рендеринга списка изображений.
 * @param {Object} props - Свойства компонента.
 * @param {AiRequestsProps} props.list - Список изображений.
 * @param {string[]} props.selectedId - ID Выбранного элемента.
 * @param {boolean} [props.isBluredImg] - Флаг блюра генерации.
 * @param {boolean} [props.isError] - Флаг ошибки генерации.
 */
const RenderImage: FC<{
  // list: GetUndressingsQuery["getUndressings"] | TUndressingFile[];
  list: { id: string; fileUrl: string | null }[];
  selectedId: string;
  isBluredImg?: boolean;
  isError?: boolean;
}> = ({ list, selectedId, isBluredImg, isError }) =>
  map(list, ({ id, fileUrl }) => {
    if (isError || !fileUrl) {
      return null;
    }

    return (
      <Image
        key={id}
        src={fileUrl}
        width={826}
        height={826}
        quality={100}
        priority
        alt=""
        className={cn(
          "block size-full translate-z-0 object-contain",
          selectedId !== id && "hide",
          isBluredImg && "blur-sm",
        )}
      />
    );
  });
