"use client";

import { useCallback, useEffect, useRef, useState } from "react";

import { Gender } from "@/.gql/graphql";

export const useToggleBg = (gender: Gender) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isBottom, setIsBottom] = useState(true);

  const handleCheckPosition = useCallback(() => {
    const viewportHeight = window.innerHeight;
    const positionWrapper =
      wrapperRef.current?.getBoundingClientRect().bottom || 0;

    const res = positionWrapper - viewportHeight;

    if (res >= -10) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, []);

  useEffect(() => {
    handleCheckPosition();
    window.addEventListener("scroll", handleCheckPosition);

    return () => window.removeEventListener("scroll", handleCheckPosition);
  }, [handleCheckPosition, gender]);

  return { wrapperRef, isBottom };
};
