"use client";

import { OfferNoTimer } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

import BgLg from "@/styles/image/offers/spring-collection.webp";

export const NewCollection: FC = () => {
  const t = useTranslations("NewCollectionOffer");

  return (
    <OfferNoTimer
      image={BgLg}
      theme="covered"
      title={<span className="text-black">{t("title")}</span>}
      text={
        <span className="text-sm/4 font-medium text-black/80">{t("text")}</span>
      }
    />
  );
};
