"use client";

import { Icon } from "@cloai/uikit";
import useEmblaCarousel from "embla-carousel-react";
import { useTranslations } from "next-intl";
import Image from "next/image";

import SweetieFoxBgImage from "@/styles/image/clothoff-landing/slide-girls/sweetie-fox.webp";
import { cn } from "@/utils/cn";

import { AnimatedCounter } from "../ui/AnimatedCounter";
import { VideoPlayer } from "../ui/VideoPlayer";

export const ModelsSlider = () => {
  const t = useTranslations("ClothoffLanding.ModelsSlider");

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "start",
    active: false, // TODO: когда будет больше 1 слайда включить
  });

  const items = [
    {
      id: "1",
      title: t("model1.title"),
      video: "/video/clothoff-landing/sweetie-fox.mp4",
      name: t("model1.name"),
      description1: t("model1.description1"),
      description2: t("model1.description2"),
      views: t.rich("model1.views", {
        count: () => <AnimatedCounter count={5264837} />,
      }),
      bgImage: SweetieFoxBgImage,
      poster: "/images/clothoff-landing/poster-sweetie-fox.webp",
    },
  ];

  return (
    <div
      className={cn(
        "relative w-full bg-clothoff-landing-secondary",
        "z-2 md:bg-transparent",
      )}
    >
      <div ref={emblaRef} className="w-full overflow-hidden rounded-3lg">
        <div className="flex">
          {items.map((item) => (
            <div
              key={item.id}
              className={cn(
                "relative w-full shrink-0 overflow-hidden rounded-3lg bg-clothoff-landing-secondary px-4 py-6",
                "md:h-116 md:pt-9 md:pb-0",
              )}
            >
              <Image
                src={item.bgImage}
                width={2340}
                height={924}
                className={cn(
                  "absolute top-0 left-0 z-0 hide h-full w-full rounded-3lg object-cover object-left",
                  "md:block",
                )}
                alt=""
              />

              <p
                className={cn(
                  "relative z-1 mb-6 text-center text-3xl font-bold",
                )}
              >
                {item.title}
              </p>

              <div
                className={cn(
                  "relative z-1 mx-auto flex flex-col items-center gap-10",
                  "md:flex-row md:justify-center",
                )}
              >
                <VideoPlayer
                  autoPlay={false}
                  className={cn("mx-0 aspect-[967/713] w-full", "md:max-w-97")}
                  src={item.video}
                  controls={false}
                  poster={item.poster as never}
                />

                <div className={cn("w-full", "md:max-w-67")}>
                  <p className="mb-3 text-2xl font-bold">{item.name}</p>
                  <p className="mb-3 text-neutral-300">{item.description1}</p>
                  <p className="mb-3 text-neutral-300">{item.description2}</p>
                  <p className="flex flex-nowrap items-center gap-2">
                    <Icon
                      as="span"
                      icon="visibility"
                      className="size-6 shrink-0"
                    />
                    {item.views}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button
        onClick={() => emblaApi?.scrollPrev()}
        className={cn(
          "absolute top-1/2 left-1 z-2 hide -translate-y-1/2 cursor-pointer",
          // "md:inline", TODO: когда будет больше 1 слайда включить
          "lg:left-6",
        )}
      >
        <Icon
          icon="keyboard_arrow_up"
          className={cn("size-6 rotate-90", "lg:size-12")}
        />
      </button>
      <button
        onClick={() => emblaApi?.scrollNext()}
        className={cn(
          "absolute top-1/2 right-1 z-2 hide -translate-y-1/2 cursor-pointer",
          // "md:inline", TODO: когда будет больше 1 слайда включить
          "lg:right-6",
        )}
      >
        <Icon
          icon="keyboard_arrow_up"
          className={cn("size-6 -rotate-90", "lg:size-12")}
        />
      </button>
    </div>
  );
};
