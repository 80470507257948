"use client";

import { PATH_AUTH } from ".constants/links";
import { Button } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { useCookies } from "react-cookie";

import { useCompany } from "@/components/hooks/useCompany";

export const AuthButton = () => {
  const t = useTranslations("ClothoffLanding");
  const { companyLower } = useCompany();
  const [cookies] = useCookies(["_r", "rulesAccepted"]);

  const authLink = () => {
    if (!companyLower.includes("clothoff.me")) {
      return PATH_AUTH;
    }

    if (cookies._r) {
      return `https://${companyLower}${PATH_AUTH}?utm_source=r&utm_medium=${cookies._r}`;
    }

    return `https://${companyLower}${PATH_AUTH}`;
  };

  return (
    <Button
      href={authLink()}
      text={t("signIn")}
      color="regular"
      size="little"
      data-event="chooseweb_header"
      disabled={!cookies.rulesAccepted}
    />
  );
};
