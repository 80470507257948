"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_EARN } from ".constants/links";
import { PORTALS } from ".constants/portals";
import { Button, CoinPackV2, PlateImage, usePortalContext } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { FC } from "react";

import { useCompany } from "@/components/hooks/useCompany";
import { useConfigContext, useGTMContext } from "@/providers";
import PlateTutorImgNudiva from "@/styles/image/tutor-nudiva/tutor-home-plate.webp";
import PlateTutorImg from "@/styles/image/tutor/tutor-home-plate.webp";

export const EarnCoinsPlate: FC = () => {
  const t = useTranslations("HomePage");
  const { sendGTM } = useGTMContext();
  const { freeCoinsAfterReferring } = useConfigContext();

  const onEarnCoins = () => {
    sendGTM({ event: GE.EARN_COINS_BUTTON });
  };

  return (
    <CoinPackV2
      href={PATH_EARN}
      scroll={true}
      imageBg={`/images/coin-pack-bg/v2/free/4.svg`}
      imageBgMeta={""}
      coins={`+${freeCoinsAfterReferring}`}
      type="free"
      text={t("freeCoins")}
      onClick={onEarnCoins}
    />
  );
};

export const ActivatePromocodeButton: FC = () => {
  const f = useTranslations("Footer");
  const { openPortal } = usePortalContext();

  return (
    <>
      <Button
        iconLeft="local_activity"
        text={f("activatePromocode")}
        color="regular"
        wide
        centerText
        onClick={() => openPortal(PORTALS.ActivatePromocode)}
      />
    </>
  );
};

export const TutorPlate: FC = () => {
  const t = useTranslations("HomePage");
  const { sendGTM } = useGTMContext();
  const { openPortal } = usePortalContext();
  const { isNudiva } = useCompany();

  const openTutorModal = () => {
    sendGTM({ event: GE.CLICK_TUTOR__HOMEPAGE });

    openPortal(PORTALS.Tutor);
  };

  return (
    <PlateImage text={t("howToImproveTheResult")} onClick={openTutorModal}>
      <Image
        src={isNudiva ? PlateTutorImgNudiva : PlateTutorImg}
        width={456}
        height={203}
        alt={t("imgMeta.howTo.alt")}
      />
    </PlateImage>
  );
};
