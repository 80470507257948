"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { useMutation } from "@apollo/client";
import { Button, usePortalContext } from "@cloai/uikit";
import { FC, useCallback, useEffect, useState, useTransition } from "react";
import { toast } from "react-toastify";

import { SIGN_RATING } from "@/.graphql";
import {
  useABtestContext,
  useGTMContext,
  useUndressContext,
} from "@/providers";

export const Rating: FC = () => {
  const { clickUndress } = useUndressContext();
  // Определяем, участвует в АБ-тесте или нет
  const { EmailTriggerFree, EmailTriggerVip } = useABtestContext();
  const { openPortal } = usePortalContext();
  const { sendGTM } = useGTMContext();

  const [activeItem, setActiveItem] = useState<number | null>(
    clickUndress?.rating ?? null,
  );
  const [signRating] = useMutation(SIGN_RATING);
  const [isPending, startTransition] = useTransition();

  const handleItemClick = useCallback(
    async (event: React.SyntheticEvent) => {
      const rating = Number(event.currentTarget.getAttribute("data-rating"));

      if (activeItem === rating) {
        return;
      }

      try {
        setActiveItem(rating);
        startTransition(async () => {
          await signRating({
            variables: {
              undressId: clickUndress!.id,
              rating,
            },
          });
          sendGTM({ event: GE.RATE_RESULT, ecommerce: { rating: rating } });
        });
      } catch (err) {
        toast.error((err as Error).message);
      } finally {
        EmailTriggerFree && openPortal(PORTALS.SubscribesFREE);
        EmailTriggerVip && openPortal(PORTALS.SubscribesVIP);
      }
    },
    [
      activeItem,
      signRating,
      clickUndress,
      sendGTM,
      EmailTriggerFree,
      openPortal,
      EmailTriggerVip,
    ],
  );

  useEffect(() => {
    setActiveItem(clickUndress?.rating ?? null);
  }, [clickUndress?.rating]);

  return (
    <div className="flex gap-2">
      <Button
        iconLeft="thumb_up"
        color={activeItem === 5 ? "white" : "regular"}
        onClick={handleItemClick}
        disabled={isPending}
        data-rating="5"
      />
      <Button
        iconLeft="thumb_down"
        color={activeItem === 1 ? "white" : "regular"}
        onClick={handleItemClick}
        disabled={isPending}
        data-rating="1"
      />
    </div>
  );
};
