"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { Button, usePortalContext } from "@cloai/uikit";
import { includes } from "lodash";
import { useTranslations } from "next-intl";
import { FC, SyntheticEvent, useRef } from "react";

import { InputUndressType } from "@/.gql/graphql";
import { getFileUrl } from "@/app/_actions/getFileUrl";
import {
  useABtestContext,
  useGTMContext,
  useMobileContext,
  useUndressContext,
} from "@/providers";
import { base64ToFile } from "@/utils/base64ToFile";

type TDownloadFileProps = {
  /**
   * Если true, то кнопка будет «широкой» (wide)
   * и с текстом (а не только иконкой).
   */
  wide?: boolean;
  disabled?: boolean;
};

/**
 * Скачивает несколько файлов (фото или видео) с задержкой через временные ссылки.
 * @param undressings Список результатов «раздевания»
 */
function downloadFiles(
  undressings: Array<{
    fileUrl?: string | null;
    undressType?: InputUndressType;
  }>,
): void {
  undressings?.forEach(({ fileUrl, undressType }, index) => {
    setTimeout(() => {
      if (!fileUrl) {
        return;
      }

      const isVideo =
        undressType === InputUndressType.Video ||
        undressType === InputUndressType.Animation;
      const extension = isVideo ? "mp4" : "png";
      const link = document.createElement("a");
      link.href = `${fileUrl}.${extension}`;
      link.download = `file-${index + 1}.${extension}`;
      link.click();
    }, index * 500);
  });
}

export const DownloadFile: FC<TDownloadFileProps> = ({ wide, disabled }) => {
  const t = useTranslations("HomePage");
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const { isMobiles } = useMobileContext();
  const { EmailTriggerFree, EmailTriggerVip } = useABtestContext();
  const { openPortal } = usePortalContext();
  const { sendGTM } = useGTMContext();
  const { clickUndress, undressings } = useUndressContext();

  // Определяем тип файла и расширение на основе undressType
  const isVideo = includes(
    [InputUndressType.Video, InputUndressType.Animation],
    clickUndress?.undressType,
  );
  const fileExtension = isVideo ? "mp4" : "png";
  const fileUrl = `${clickUndress?.fileUrl ?? ""}.${fileExtension}`;

  const onClick = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();

    // Отправляем событие в GTM
    sendGTM({ event: isVideo ? GE.DOWNLOAD_VIDEO : GE.DOWNLOAD_IMAGE });

    try {
      if (isMobiles) {
        // Для мобильных устройств используем нативный шаринг
        const files: File[] = await Promise.all(
          (undressings ?? []).map(async ({ fileUrl, undressType }) => {
            if (!fileUrl) {
              return null;
            }

            const isVideoFile = includes(
              [InputUndressType.Animation, InputUndressType.Video],
              undressType,
            );
            const fileName = isVideoFile ? "video" : "image";
            const response = await getFileUrl(fileUrl);

            if (response?.file) {
              return base64ToFile(response.file, fileName, isVideoFile);
            }
            return null;
          }),
        ).then((res) => res.filter(Boolean) as File[]);

        await navigator.share({
          files,
          title: isVideo ? "Video" : "Photos",
        });
      } else {
        // На десктопе скачиваем файлы через временные ссылки
        downloadFiles(undressings ?? []);
      }
    } catch (error) {
      console.error("Ошибка при скачивании/шаринге файлов:", error);
    } finally {
      EmailTriggerFree && openPortal(PORTALS.SubscribesFREE);
      EmailTriggerVip && openPortal(PORTALS.SubscribesVIP);
    }
  };

  return (
    <Button
      href={fileUrl}
      target="_blank"
      download={`file.${fileExtension}`}
      iconLeft="download"
      color="regular"
      text={t("save")}
      onClick={onClick}
      disabled={disabled}
      ref={downloadRef}
      nativeLink
      centerText={wide}
      wide
    />
  );
};
