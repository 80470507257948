"use client";

import { GE, GTMEvent } from ".constants/gtmEvents";
import { PATH_BUY, PATH_PAY } from ".constants/links";
import { CoinPackV2 } from "@cloai/uikit";
import { FC, memo, useMemo } from "react";

import { GetMethodsQuery } from "@/.gql/graphql";
import { useCompany } from "@/components/hooks/useCompany";
import { useABtestContext, useGTMContext } from "@/providers";
import { getBonus } from "@/utils/getDiscount";
import { getLocalePrice } from "@/utils/getLocalPrice";

// >>> <BuyItemWide/>
type TBuyItemWideProps = {
  index: number;
  coinPack: GetMethodsQuery["getPacks"][0];
  event?: GTMEvent;
};
export const BuyItemWide: FC<TBuyItemWideProps> = memo(
  ({ index, coinPack, event }) => {
    const { sendGTM } = useGTMContext();
    const { AB_Payment_HUB } = useABtestContext();

    const { isNudiva } = useCompany();

    const onClick = () => {
      sendGTM({
        event: event || GE.CHOICE_VALUE_MODAL_PACKAGES,
        ecommerce: {
          currency: coinPack.currency,
          value: coinPack.price,
        },
      });
    };

    const bgPath = useMemo(
      () =>
        isNudiva
          ? "/images/coin-pack-bg/v2/vip_for_nudiva/"
          : "/images/coin-pack-bg/v2/vip/",
      [isNudiva],
    );

    const bgNumber = (index + 1) % 4;

    const bgPack = useMemo(() => {
      switch (bgNumber) {
        case 0:
          return 4;
        default:
          return bgNumber;
      }
    }, [bgNumber]);

    const methodsLink = AB_Payment_HUB ? PATH_PAY : PATH_BUY;

    return (
      <CoinPackV2
        key={index}
        href={`${methodsLink}/${coinPack.coin}`}
        target={AB_Payment_HUB ? "_blank" : ""}
        imageBg={`${bgPath}${bgPack}.svg`}
        imageBgMeta={""}
        coins={coinPack.coin}
        coins_with_discount={
          coinPack.discount
            ? getBonus(coinPack.coin, coinPack.discount)
            : undefined
        }
        hot={false}
        discount={coinPack.discount}
        price={getLocalePrice(coinPack.currency, coinPack.price)}
        onClick={onClick}
        type={isNudiva ? "vip_for_nudiva" : "vip"}
      />
    );
  },
);
BuyItemWide.displayName = "BuyItemWide";
// <<< <BuyItemWide/>
