"use client";

import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useWindowSize } from "react-use";

interface Props extends PropsWithChildren {
  targetPlatform: "mobile" | "desktop";
  initialIsDesktop: boolean;
}

export const AdaptivePortal: FC<Props> = ({
  children,
  initialIsDesktop,
  targetPlatform,
}) => {
  const { width } = useWindowSize();

  const initIsShow =
    targetPlatform === "desktop" ? initialIsDesktop : !initialIsDesktop;

  const [isShow, setIshow] = useState<boolean>(initIsShow);

  useEffect(() => {
    const isDesktop = width >= 1024;
    if (
      (isDesktop && targetPlatform === "desktop") ||
      (!isDesktop && targetPlatform === "mobile")
    ) {
      setIshow(true);
    } else {
      setIshow(false);
    }
  }, [width, initialIsDesktop, targetPlatform]);

  if (!isShow) {
    return null;
  }
  return children;
};
