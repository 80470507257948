"use client";

import { Icon } from "@cloai/uikit";
import {
  DetailedHTMLProps,
  SourceHTMLAttributes,
  useImperativeHandle,
  useRef,
  useState,
  VideoHTMLAttributes,
} from "react";

import { cn } from "@/utils/cn";

type VideoPlayerProps = DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
> & {
  type?: SourceHTMLAttributes<HTMLSourceElement>["type"];
};

export const VideoPlayer = ({
  className,
  autoPlay = false,
  loop = false,
  muted = true,
  playsInline = true,
  preload: _preload = "none",
  type = "video/mp4",
  src,
  poster,
  controls,
  ref,
  ...props
}: VideoPlayerProps) => {
  const [playing, setPlaying] = useState(autoPlay);
  const [preload, setPreload] = useState(_preload);

  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = async () => {
    if (videoRef.current?.paused) {
      await videoRef.current.play();
      setPlaying(true);
      return;
    }

    if (videoRef.current?.played) {
      videoRef.current?.pause();
      setPlaying(false);
    }
  };

  const play = async () => {
    await videoRef.current?.play();
    setPlaying(true);
  };

  const pause = () => {
    videoRef.current?.pause();
    setPlaying(false);
  };

  useImperativeHandle(
    ref,
    () =>
      ({
        ...videoRef.current,
        play,
        pause,
        togglePlay,
      }) as HTMLVideoElement,
  );

  const onMouseEnter = () => {
    if (preload === "none") {
      setPreload("metadata");
    }
  };

  return (
    <div className="group/video relative w-auto cursor-pointer">
      <video
        ref={videoRef}
        loop={loop}
        autoPlay={autoPlay}
        muted={muted}
        playsInline={playsInline}
        preload={preload}
        onMouseEnter={onMouseEnter}
        poster={poster}
        controls={playing && controls}
        className={cn("mx-auto rounded-3lg bg-black object-cover", className)}
        onPlay={() => play()}
        onPause={() => pause()}
        onClick={togglePlay}
        {...props}
      >
        <source src={src} type={type} />
      </video>

      <Icon
        onClick={() => play()}
        icon={playing ? "pause" : "play_arrow"}
        size={15}
        className={cn(
          "absolute top-1/2 left-1/2 z-3 -translate-x-1/2 -translate-y-1/2 translate-z-0 will-change-[opacity,transform]",
          "flex size-19 items-center justify-center rounded-full border-5 border-white drop-shadow-lg duration-200 select-none",
          "active:scale-95",
          playing
            ? "pointer-events-none opacity-0 group-hover/video:opacity-50"
            : "opacity-50 group-hover/video:opacity-100",
        )}
      />
    </div>
  );
};
