"use client";

import { FC, HTMLAttributes, useEffect, useState } from "react";

import { useHeadersContext } from "@/providers";
import { getIsPwa } from "@/utils/getIsPwa";

import { PwaButton } from "./PwaButton";

export const Pwa: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { ["user-agent"]: userAgent } = useHeadersContext();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isDisabledPwa = /Firefox|XiaoMi|MiuiBrowser/i.test(userAgent);
    if (isDisabledPwa) {
      return setIsVisible(false);
    }

    const isPWA = getIsPwa();
    if (isPWA) {
      return setIsVisible(false);
    }

    setIsVisible(true);
  }, [userAgent]);

  if (!isVisible) {
    return null;
  }

  return <PwaButton setIsVisibleAction={setIsVisible} {...props} />;
};
