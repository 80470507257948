"use client";

import { find } from "lodash";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import { FC } from "react";

import { useAiRequestsContext, useUndressContext } from "@/providers";
import PromptImg from "@/styles/image/prompt.webp";

const PLATE_CLASSLIST =
  "bg-card relative flex w-full items-center gap-4 rounded-xl p-2";

export const UndressDetailsPlates: FC = () => {
  const t = useTranslations("HomePage.settingsPopup");
  const {
    clickSettings,
    clickUndressStatus,
    clickUndressingFile,
    isOpenCustomizeSettings,
    selectedSetCount,
  } = useUndressContext(); // Контекст с данными о "раздевании" (`undress`)

  const locale = useLocale();

  const { costume, pose, set } = clickSettings;

  const { costumes, poses, sets } = useAiRequestsContext();
  const getCostume = find(costumes, { id: costume });
  const getPose = find(poses, { id: pose });
  const getSet = find(sets, { id: set });

  const promtObj = {
    image: PromptImg.src,
    name: { [locale]: t("prompt") },
    collection: { [locale]: t("prompt") },
  };

  const leftCard = getSet ?? getCostume ?? promtObj;

  /* Ничего не отображаем, если:
   * - нет загруженного изображения
   * - окрыты доп.настройки
   */
  if (
    !clickUndressStatus.isInit ||
    !clickUndressingFile ||
    isOpenCustomizeSettings
  ) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <div className={PLATE_CLASSLIST}>
        <Image
          src={leftCard?.image ?? ""}
          width={56}
          height={56}
          priority
          alt={""}
          className="aspect-square size-14 rounded-sm bg-body object-contain"
        />

        <span className="text-base/5 font-medium">
          {leftCard?.collection[locale] ||
            leftCard?.collection["en"] ||
            leftCard?.name[locale] ||
            leftCard?.name["en"] ||
            leftCard?.name}
        </span>

        {/* Кол-во итемов в выбранной коллекции */}
        {getSet && (
          <div className="absolute top-3 right-3 flex items-center gap-2 !text-base/4 font-medium">
            <span className="material-icons" data-icon="photo_camera" />
            <span>{selectedSetCount}</span>
          </div>
        )}
      </div>

      {getPose && (
        <div className={PLATE_CLASSLIST}>
          <Image
            src={getPose?.image ?? ""}
            width={56}
            height={56}
            priority
            alt={""}
            className="aspect-square size-14 rounded-sm bg-body object-contain"
          />

          <span className="text-base/5 font-medium">
            {getPose?.name[locale] || getPose?.name["en"]}
          </span>
        </div>
      )}
    </div>
  );
};
