/**
 * Захватывает кадры из видео в равных интервалах времени и возвращает их вместе с длительностью видео.
 *
 * Данная функция:
 * 1. Создаёт временный элемент <video> для загрузки метаданных и получения длительности.
 * 2. Дожидается загрузки метаданных и, при необходимости, проверяет максимальную длительность.
 * 3. Рассчитывает равномерно распределённые временные точки по длительности видео.
 * 4. Для каждой точки параллельно создаёт отдельный <video> и <canvas>, перематывает, захватывает кадр,
 *    сохраняет его в формате DataURL.
 * 5. Возвращает массив скриншотов в правильном порядке и длительность видео.
 *
 * @param {string} src URL видеофайла (может быть Blob URL или ссылка на файл)
 * @param {number} framesToCapture Количество кадров, которые нужно извлечь из видео
 * @param {number} [maxDuration] Максимально допустимая длительность видео в секундах
 * @returns {Promise<{ frames: string[], duration: number }>} Промис, резолвящийся в объект с кадрами и длительностью
 * @throws Если длительность видео превышает maxDuration, бросается ошибка
 */
export const createScreenshots = async (
  src: string,
  framesToCapture: number,
  maxDuration?: number,
): Promise<{ frames: string[]; duration: number }> => {
  // Создаём временный видеоэлемент для получения метаданных
  const tempVideo = document.createElement("video");
  tempVideo.src = src;
  tempVideo.autoplay = false;
  tempVideo.loop = false;
  tempVideo.volume = 0;
  tempVideo.muted = true;

  // Ждём загрузки метаданных, чтобы знать длительность видео
  await new Promise<void>((resolve, reject) => {
    const onLoadedMetadata = () => {
      tempVideo.removeEventListener("loadedmetadata", onLoadedMetadata);
      resolve();
    };

    const onError = () => {
      tempVideo.removeEventListener("error", onError);
      reject(new Error("failedToUploadVideo"));
    };

    if (tempVideo.readyState >= 1) {
      resolve();
    } else {
      tempVideo.addEventListener("loadedmetadata", onLoadedMetadata, {
        once: true,
      });
      tempVideo.addEventListener("error", onError, { once: true });
    }
  });

  const duration = tempVideo.duration;

  // Проверка максимально допустимой длительности
  if (maxDuration !== undefined && duration > maxDuration) {
    tempVideo.remove();
    throw new Error("tooLongVideo");
  }

  // Интервалы между кадрами
  const interval = duration / (framesToCapture + 1);
  const frameTimes: number[] = [];
  for (let i = 1; i <= framesToCapture; i++) {
    frameTimes.push(interval * i);
  }

  // Закрываем временное видео, оно нам больше не нужно
  tempVideo.remove();

  /**
   * Функция для захвата одного кадра в определённый момент времени.
   * Создаёт новый <video> и <canvas> для каждого кадра, чтобы можно было делать всё параллельно.
   *
   * @param {string} src URL видео
   * @param {number} time Время в секундах, по которому нужно захватить кадр
   * @returns {Promise<string>} Промис с DataURL кадра
   */
  const captureFrameAtTime = (src: string, time: number): Promise<string> =>
    new Promise<string>((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      video.src = src;
      video.autoplay = false;
      video.loop = false;
      video.volume = 0;
      video.muted = true;

      const onError = () => {
        video.removeEventListener("error", onError);
        video.remove();
        canvas.remove();
        reject(new Error("videoRewindError"));
      };

      const onSeeked = () => {
        video.removeEventListener("seeked", onSeeked);

        if (!context) {
          video.remove();
          canvas.remove();
          reject(new Error("videoCanvasError"));
          return;
        }

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const dataURL = canvas.toDataURL("image/jpeg");

        video.remove();
        canvas.remove();
        resolve(dataURL);
      };

      // Дожидаемся метаданных для определения размеров canvas
      const onLoadedMetadata = () => {
        video.removeEventListener("loadedmetadata", onLoadedMetadata);

        // Устанавливаем нужное время
        video.addEventListener("seeked", onSeeked, { once: true });
        video.addEventListener("error", onError, { once: true });
        video.currentTime = time;
      };

      video.addEventListener("loadedmetadata", onLoadedMetadata, {
        once: true,
      });
      video.addEventListener("error", onError, { once: true });
    });

  // Параллельно запрашиваем кадры для всех временных точек
  const frames = await Promise.all(
    frameTimes.map((time) => captureFrameAtTime(src, time)),
  );

  return { frames, duration };
};
