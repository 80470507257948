"use client";

import { GE, GTMEvent } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { usePortalContext, InfoBoxV4, Button } from "@cloai/uikit";
import { useLocale } from "next-intl";
import { ReactNode } from "react";

import {
  useFlag,
  LanguageCode,
  useLanguage,
} from "@/components/hooks/useLanguage";
import { useGTMContext } from "@/providers";

type Variant = "desktop" | "mobile";

type LangButtonProps = {
  variant?: Variant;
  event?: GTMEvent;
};

export const LangButton = ({
  variant = "desktop",
  event = GE.CHOOSE_LANGUAGE_HEADER,
}: LangButtonProps) => {
  const { openPortal } = usePortalContext();
  const { sendGTM } = useGTMContext();
  const locale = useLocale() as LanguageCode;
  const flag = useFlag();
  const currentLanguage = useLanguage();

  const openLanguageModal = () => {
    sendGTM({ event });
    openPortal(PORTALS.ChangeLang);
  };

  if (process.env.NEXT_PUBLIC_IS_CN) {
    return null;
  }

  const variants: Record<Variant, ReactNode> = {
    desktop: (
      <div className="hide md:block">
        <InfoBoxV4
          iconLeft={flag(locale as LanguageCode)}
          text={locale.toUpperCase()}
          onClick={openLanguageModal}
        />
      </div>
    ),
    mobile: (
      <Button
        iconLeft={flag(locale)}
        text={currentLanguage}
        iconRight="arrow_drop_down"
        color="outline"
        centerText
        wide
        onClick={openLanguageModal}
      />
    ),
  };

  return variants[variant];
};
