"use client";

import {
  Box,
  PlateProcessStatus,
  ProcessLine,
  ProcessStatusText,
} from "@cloai/uikit";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { useTranslations } from "next-intl";
import { FC, memo, useEffect, useState } from "react";
import { useInterval } from "react-use";

import { useTimer } from "@/components/hooks/useTimer";
import { useUndressContext, Undressing } from "@/providers";

import { Rating } from "./Rating";

dayjs.extend(duration);
dayjs.extend(utc);

interface TimerProps {
  endDate: string;
}

// Компонент таймера, который отображает оставшееся время
const Timer: FC<TimerProps> = memo(({ endDate }) => {
  const time = useTimer(endDate);

  return (
    <>
      {time?.formattedHours && `${time.formattedHours}:`}
      {time?.formattedMinutes}:{time?.formattedSeconds}
    </>
  );
});

Timer.displayName = "Timer";

interface LineProps {
  totalSeconds: number;
  endDate: string;
  orange?: boolean;
}

// Компонент линии прогресса, отображающий статус процесса
const Line: FC<LineProps> = memo(({ endDate, totalSeconds, orange }) => {
  const seconds = useSeconds(endDate);
  const width = calculateLineWidth(seconds, totalSeconds);

  return <ProcessLine width={width} color={orange ? "orange" : "violet"} />;
});

Line.displayName = "Line";

// Функция вычисления ширины линии прогресса
const calculateLineWidth = (
  elapsedSeconds: number,
  totalSeconds: number,
): number => ((totalSeconds - elapsedSeconds) / totalSeconds) * 100;

// Функция для вычисления количества оставшихся секунд до заданной даты
const asSeconds = (successAt?: string, startTime?: Dayjs | null) => {
  if (!successAt) {
    return 0;
  }
  const now = dayjs();
  const targetDateTime = dayjs(successAt);
  const duration = dayjs.duration(targetDateTime.diff(startTime ?? now));
  return Math.max(0, Math.round(duration.asSeconds())); // Убедиться, что не возвращаем отрицательные значения
};

// Хук для управления количеством оставшихся секунд
const useSeconds = (successAt?: string) => {
  const initialSeconds = asSeconds(successAt);
  const [seconds, setSeconds] = useState(initialSeconds);

  useInterval(
    () => setSeconds((prevSeconds) => Math.max(0, prevSeconds - 1)),
    seconds > 0 ? 1000 : null, // Очищаем интервал, когда время достигло 0
  );

  useEffect(() => {
    setSeconds(asSeconds(successAt));
  }, [successAt]);

  return seconds;
};

interface LineBlockProps {
  undress: Undressing;
  differenceInSeconds: number;
}

// Компонент, отображающий блок линии с таймером и статусом
const LineBlock: FC<LineBlockProps> = memo(
  ({ undress, differenceInSeconds }) => {
    const seconds = useSeconds(undress.successAt);
    const time = useTimer(undress.successAt);
    const t = useTranslations("HomePage.statusProcess");

    if (
      (undress && seconds < 1) ||
      (time?.hours === 0 && time?.minutes === 0 && time?.seconds === 0)
    ) {
      return (
        <>
          <ProcessStatusText
            text={t.rich("noticeNoClose", {
              br: () => <br />,
            })}
            color="animcolor"
            center
          />
        </>
      );
    }

    return (
      <>
        <ProcessStatusText
          text={
            <>
              <span>{undress.isPaid ? t("vipPhoto") : t("standartPhoto")}</span>
              <span>
                <Timer endDate={undress.successAt} />
              </span>
            </>
          }
          wide
          flex
        />

        <Line
          endDate={undress.successAt}
          totalSeconds={differenceInSeconds}
          orange={undress.isPaid}
        />
      </>
    );
  },
);

LineBlock.displayName = "LineBlock";

interface StatusProps {
  undress: Undressing | null | undefined;
}

// Основной компонент, который отображает статус процесса обработки
export const Status: FC<StatusProps> = memo(({ undress }) => {
  const t = useTranslations("HomePage.statusProcess");
  const { startGenTime } = useUndressContext();
  const differenceInSeconds = asSeconds(undress?.successAt, startGenTime);

  if (!undress) {
    return null;
  }

  // обработка фотографии
  if (undress.status === 0) {
    return (
      <PlateProcessStatus center>
        <LineBlock
          undress={undress}
          differenceInSeconds={differenceInSeconds}
        />
      </PlateProcessStatus>
    );
  }

  // обработка завершена
  if (undress.status === 1) {
    return undress.isGuest ? (
      // гость
      <PlateProcessStatus center>
        <ProcessStatusText text={t("authNowNoBlur")} color="violet" center />
      </PlateProcessStatus>
    ) : (
      //  авторизованный пользователь
      <Rating />
    );
  }

  //  кончились коины
  // if (noCoins) {
  //   return (
  //     <PlateProcessStatus center>
  //       <ProcessStatusText
  //         text={t.rich("noticeNoCoins", {
  //           br: () => <br />,
  //         })}
  //         center
  //       />
  //     </PlateProcessStatus>
  //   );
  // }

  // ошибка обработки
  if (undress.error) {
    return (
      <PlateProcessStatus center>
        <Box gap={0}>
          <ProcessStatusText
            text={t("errorPhotoIsNotProcessed")}
            color="orange"
            center
          />
          <ProcessStatusText
            text={t(undress.error as never)}
            center
            style={{ lineHeight: "0.9" }}
          />
        </Box>
      </PlateProcessStatus>
    );
  }

  return null;
});

Status.displayName = "Status";
