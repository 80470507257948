// usePercent.ts

/**
 * Вычисляет процентное значение `value` в пределах диапазона `limit`.
 *
 * @param value - Текущее значение.
 * @param limit - Диапазон значений [минимум, максимум].
 * @returns Процентное значение `value` в пределах `limit`, округленное до двух знаков.
 */
export const calculatePercent = (
  value: number,
  limit: [number, number],
  toFixed = 2,
): number => {
  const [min, max] = limit;
  const range = max - min;

  if (range === 0) {
    console.warn("Диапазон (max - min) равен нулю. Возвращается 0.");
    return 0;
  }

  const calculatedPercent = ((value - min) / range) * 100;
  return Number(calculatedPercent.toFixed(toFixed));
};
