"use client";

import { PORTALS } from ".constants/portals";
import { Button } from "@cloai/uikit";
import { usePortalContext } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";

import { ResetReUseFacePortal } from "@/portals";
import { useUndressContext } from "@/providers";

export const ResetButton: FC = () => {
  const t = useTranslations("HomePage");
  const { openPortal } = usePortalContext();
  const {
    clickUndressStatus,
    clickUndressingFile,
    isOpenCustomizeSettings,
    reUseFaceObj,
  } = useUndressContext();

  if (
    !reUseFaceObj ||
    !clickUndressStatus.isInit ||
    clickUndressingFile ||
    isOpenCustomizeSettings
  ) {
    return null;
  }

  const handleReset = () => {
    openPortal(PORTALS.ResetReUseFace);
  };

  return (
    <>
      <Button
        iconLeft="autorenew"
        text={t("newGenerate")}
        centerText
        color="regular"
        wide
        onClick={handleReset}
      />

      <ResetReUseFacePortal />
    </>
  );
};
