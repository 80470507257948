/**
 * Компонент GiftPlate отображает информацию о сегодняшнем подарке и предоставляет доступ к модальному окну дорожной карты подарков.
 *
 * @remarks
 * Функциональность:
 * - Отображает текущий подарок дня, его тип и количество (если применимо).
 * - Показывает пользователю, что он может получать подарки каждый день.
 * - При клике открывает портал с дорожной картой подарков (GiftRoadmap).
 *
 * Взаимодействие с контекстом:
 * - Использует `useProfileContext` для проверки наличия профиля пользователя.
 * - Использует `useGiftContext` для получения текущего дня (`day`) и информации о сегодняшнем подарке (`todayGift`).
 * - Использует `useGTMContext` для отправки аналитических событий.
 * - Использует `usePortalContext` для открытия портала.
 *
 * Логика:
 * - Если профиль или сегодняшний подарок отсутствуют, компонент не рендерится.
 * - Определяет тип подарка (`free` или `vip`) на основании типа бонуса.
 * - Определяет отображаемый текст для подарка в зависимости от его типа (промокод, поза, костюм, монеты).
 * - По клику на подарок отправляет GTM-событие и открывает модальное окно с дорожной картой подарков.
 *
 * @returns JSX-элемент с отображением подарка дня или `null`, если профиль или подарок отсутствуют.
 */

"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import { Box, CoinPackV2, ContentText, usePortalContext } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, memo } from "react";

import { UserBonuseType } from "@/.gql/graphql";
import { useProfileContext, useGiftContext, useGTMContext } from "@/providers";
import { getGiftIcon } from "@/utils/getGiftIcon";

/** Компонент GiftPlate отображает информацию о сегодняшнем подарке и предоставляет доступ к модальному окну дорожной карты подарков. */
export const GiftPlate: FC = memo(() => {
  const t = useTranslations("HomePage");
  const { sendGTM } = useGTMContext();
  const profile = useProfileContext();
  const { openPortal } = usePortalContext();
  const { day, todayGift } = useGiftContext();

  // Если нет профиля или сегодняшнего подарка, ничего не отображаем
  if (!profile || !todayGift) {
    return null;
  }

  /**
   * Определяем, какой текст отображать для текущего подарка:
   * - PromoCode: "Promo"
   * - Pose: "Pose"
   * - Costume: "Nude"
   * - CoinsUserBonus: "x{количество VIP-коинов}"
   * - FreeCoinsUserBonus: "x{количество FREE-коинов}"
   */
  const giftCtx = () => {
    switch (true) {
      case todayGift.type === UserBonuseType.PromoCode:
        return "Promo";
      case todayGift.type === UserBonuseType.Pose:
        return "Pose";
      case todayGift.type === UserBonuseType.Costume:
        return "Nude";
      case todayGift.__typename === "CoinsUserBonus":
        return `x${todayGift.coins}`;
      case todayGift.__typename === "FreeCoinsUserBonus":
        return `x${todayGift.freeCoins}`;
      default:
        return "";
    }
  };

  /**
   * Определяем тип подарка:
   * - Если это FreeCoins или PromoCode — "free"
   * - Иначе — "vip"
   */
  const type =
    todayGift.type === UserBonuseType.FreeCoins ||
    todayGift.type === UserBonuseType.PromoCode
      ? "free"
      : "vip";

  /**
   * Обработчик клика по подарку:
   * - Отправляет событие в GTM
   * - Открывает портал с дорожной картой подарков
   */
  const onClick = () => {
    sendGTM({ event: GE.GIFT_BUTTON });
    openPortal(PORTALS.GiftRoadmap);
  };

  return (
    <Box gap={12}>
      <ContentText text={t("getGiftsEveryDay")} color="violet" center />
      <CoinPackV2
        imageBg={`/images/coin-pack-bg/v2/gift-pack-bg.svg`}
        imageBgMeta={""}
        coins={giftCtx()}
        type={type}
        text={t("dayNumber", { number: day })}
        icon={getGiftIcon[todayGift.type]}
        onClick={onClick}
      />
    </Box>
  );
});

GiftPlate.displayName = "GiftPlate";
