"use client";

import { useLocale, useTranslations } from "next-intl";

import { AiRequest, Gender } from "@/.gql/graphql";
import {
  useAiRequestsContext,
  useProfileContext,
  useUndressContext,
} from "@/providers";

import { useButtonIcon } from "./useButtonIcon";

export const useButtonText = () => {
  const t = useTranslations("HomePage");
  const profile = useProfileContext();
  const { costumes, poses, sets } = useAiRequestsContext();
  const {
    clickSettings,
    initialSettings,
    undressingCost,
    undressingsFiles,
    videoSettings,
  } = useUndressContext();

  const locale = useLocale();

  const { gender, costume, pose, set } = clickSettings;

  /**
   * Функция для получения имени тэга по его `id` из массива тегов (`AiRequests`).
   * Если доступно имя на текущем `locale`, берём его, иначе `fallback` на "en".
   */
  const getTagNameById = (id: string, tags: AiRequest[]) => {
    const currentTag = tags.find((tg) => tg.id === id);
    return currentTag
      ? currentTag.collection[locale] ||
          currentTag.collection["en"] ||
          currentTag.name[locale] ||
          currentTag.name["en"]
      : null;
  };

  // Иконка для кнопки раздевания
  const { undressButtonIcon } = useButtonIcon();

  /**
   * Функция для определения текста кнопки "раздеть" (undressButtonText).
   * Зависит от состояния (авторизован/гость, выбрана ли коллекция, поза, костюм).
   */
  const undressButtonText = () => {
    /** Если авторизован и загружена фотка(-и) */
    if (
      profile &&
      (undressingsFiles.length || videoSettings.videoDuration > 0)
    ) {
      switch (true) {
        /** Если загружено видео */
        case videoSettings.videoDuration > 0:
          return t.rich("undressFor", {
            coins: undressingCost,
            icon: () => undressButtonIcon,
          });

        /** Если выбрана коллекция */
        case gender !== Gender.Male && set !== initialSettings.set:
          return t.rich("clothesFor", {
            clothes: getTagNameById(set, sets),
            coins: undressingCost,
            icon: () => undressButtonIcon,
          });

        /** Если выбрана НЕдефолтная поза */
        case gender !== Gender.Male && pose !== initialSettings.pose:
          return t.rich("clothesFor", {
            clothes: getTagNameById(pose, poses),
            coins: undressingCost,
            icon: () => undressButtonIcon,
          });

        /** Если выбран НЕ nude-костюм */
        case gender !== Gender.Male && costume !== costumes[0].id:
          return t.rich("clothesFor", {
            clothes: getTagNameById(costume, costumes),
            coins: undressingCost,
            icon: () => undressButtonIcon,
          });

        default:
          return t.rich("undressFor", {
            coins: undressingCost,
            icon: () => undressButtonIcon,
          });
      }
    }

    // для гостей
    return t("undress");
  };

  return undressButtonText;
};
