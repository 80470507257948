"use client";

import { UploadedPhotoBlock } from "@cloai/uikit";
import _ from "lodash";
import { FC, useMemo, useRef } from "react";

import { useHeadersContext, useUndressContext } from "@/providers";

import { LoaderByHost } from "../../loader/LoaderByHost";
import { MAX_CUT_VIDEO_DURATION } from "../utils/useDropzoneSettings";
import { Frames } from "../video-editor/Frames";
import { RangeControl } from "../video-editor/RangeControl";

/**
 * Компонент VideoBlock отвечает за отображение видеоблока с возможностью
 * выбора определённого диапазона видео (cut points) и управления им.
 *
 * Он интегрирован с контекстами useHeadersContext и useUndressContext, чтобы получить необходимые данные:
 * - URL видео,
 * - статус обработки (loading, noCoins),
 * - доступные кадры (videoFrames),
 * - выбранный диапазон (videoCutPoints),
 * - и логику обновления состояния видео (updateVideoSetting).
 *
 * Видео отображается внутри UploadedPhotoBlock.
 * Если доступны кадры (videoFrames), над видео добавляется RangeControl,
 * позволяющий выбрать диапазон воспроизведения. Frames отображает последовательность кадров.
 *
 * При изменении диапазона (videoCutPoints) вызвается handleVideoCutPointsChange, обновляющая состояние.
 *
 * Если пользователь не в состоянии peredachi раздевания (undressing) или нет монет (noCoins),
 * и статус обработки не позволяет, кнопка сброса (onReset) не отображается.
 *
 * Пример использования:
 *
 * ```typescript
 * // Внутри какого-то компонента
 * <VideoBlock />
 * ```
 */
export const VideoBlock: FC = () => {
  // Получаем host из useHeadersContext
  const { host } = useHeadersContext();

  // Деструктурируем нужные данные из useUndressContext
  const {
    clickFileId,
    isOpenCustomizeSettings,
    loading,
    onReset,
    undressingCost,
    undressings,
    updateVideoSetting,
    videoSettings,
  } = useUndressContext();

  /**
   * Мемозируем текущий объект undress, основываясь на clickFileId.
   * Если undressings нет, возвращаем undefined.
   */
  const undress = useMemo(() => {
    if (!undressings) {
      return;
    }
    return undressings.find(({ id }) => id === clickFileId);
  }, [clickFileId, undressings]);

  // Извлекаем параметры видео из videoSettings
  const { video, videoCutPoints, videoDuration } = videoSettings;

  /**
   * Статус undress преобразуем в число для удобства проверки.
   * Используется для определения состояния интерфейса, например blur.
   */
  const status = useMemo(() => Number(undress?.status), [undress?.status]);

  /**
   * Обработчик изменения cutPoints (диапазона воспроизведения видео).
   * Вызывается при движении ползунков RangeControl.
   * @param newValue Новый диапазон [minValue, maxValue].
   */
  const handleVideoCutPointsChange = (newValue: [number, number]) => {
    updateVideoSetting("videoCutPoints", newValue);
  };

  // Создаём ref для видео элемента, чтобы RangeControl и другие компоненты могли им управлять.
  const videoRef = useRef<HTMLVideoElement>(null);

  // Если видео не задано, ничего не отображаем.
  if (!video) {
    return null;
  }

  return (
    <UploadedPhotoBlock
      src={video}
      type="video"
      blur={
        loading || [0, 2, 3].includes(status) // Если статус в одном из этих значений, применяем blur.
      }
      loader={<LoaderByHost host={host} />} // Отображаем лоадер при загрузке.
      processing={loading || [0].includes(status)}
      onReset={
        !undressings && !loading && !isOpenCustomizeSettings
          ? onReset
          : undefined
      }
      project="clothoff"
      videoRef={videoRef}
    >
      {/**
       * Если в videoSettings есть кадры (videoFrames не пуст),
       * отображаем RangeControl для выбора диапазона и список кадров (Frames).
       */}
      {!_.isEmpty(videoSettings.videoFrames) && (
        <RangeControl
          theme="with_bg" // Тема с фоновым заполнением
          limit={[0, videoDuration]} // Лимиты от 0 до длительности видео
          value={videoCutPoints} // Текущее значение cutPoints
          minRange={Math.min(1, videoDuration)} // Минимальная разница, не меньше 1 сек или меньше, если видео короче
          maxRange={MAX_CUT_VIDEO_DURATION} // Максимальная разница в секундах
          step={0.1} // Шаг 0.1 для точной настройки диапазона
          onChangeValue={handleVideoCutPointsChange}
          undressingCost={undressingCost}
          videoRef={videoRef} // Передаём ref на video для синхронизации воспроизведения
        >
          {/** Отображаем список кадров видео */}
          <Frames frames={videoSettings.videoFrames} />
        </RangeControl>
      )}
    </UploadedPhotoBlock>
  );
};

VideoBlock.displayName = "VideoBlock";
