"use client";

import { BurgerTrigger } from "@/components/burger-menu/BurgerTrigger";

import { BurgerSidebar } from "./BurgerSidebar";

export const BurgerMenu = () => (
  <>
    <BurgerTrigger />
    <BurgerSidebar />
  </>
);
