"use client";

import { isEmpty, map } from "lodash";
import { useTranslations } from "next-intl";
import { FC } from "react";

import { AiRequestsQuery } from "@/.gql/graphql";
import {
  type AiRequestsProps,
  PhotoSettings,
  useUndressContext,
  useUndressSettingsContext,
} from "@/providers";

import { useRenderItem } from "./useRenderItem";

/**
 * Компонент списка настроек (одежда, позы, сеты).
 * Рендерит список секций с настройками для выбора одежды, поз и сетов.
 * Поддерживает динамическое обновление `sectionsRef` при изменении массивов данных.
 *
 * @component
 * @example
 * return (
 *   <MainSettingsList />
 * )
 */
export const MainSettingsList: FC = () => {
  const t = useTranslations("HomePage.settingsPopup");
  const { clickSettings } = useUndressContext();
  const { wrapperRef, sectionsRef, handleScroll, costumes, poses, sets } =
    useUndressSettingsContext();

  const { renderFilterItems, userPromt } = useRenderItem();

  const { costume, pose, set } = clickSettings;

  /**
   * Функция для установки ссылки (ref) на секцию с настройками.
   *
   * @param {number} index - Индекс секции.
   * @returns {function} Функция, которая устанавливает ref на элемент секции.
   */
  const setSectionRef = (index: number) => (el: HTMLDivElement | null) => {
    if (el) {
      sectionsRef.current.set(index, el);
    }
  };

  /**
   * Обновляет `sectionsRef` при изменении массивов данных (`costumes`, `poses`, `sets`).
   * Очищает старые данные в `sectionsRef` и добавляет новые, если массивы не пустые.
   */
  // useEffect(() => {
  //   // Обновление ссылок на секции при изменении массивов
  //   sectionsRef.current.clear();

  //   if (!isEmpty(costumes)) {
  //     sectionsRef.current.set(0, null);
  //   }
  //   if (!isEmpty(poses)) {
  //     sectionsRef.current.set(1, null);
  //   }
  //   if (!isEmpty(sets)) {
  //     sectionsRef.current.set(2, null);
  //   }
  // }, [costumes, poses, sets, sectionsRef]);

  // console.log("sectionsRef: ", sectionsRef.current);

  /**
   * Рендерит секцию настроек (одежда, позы, сеты).
   *
   * @param {number} index - Индекс секции.
   * @param {AiRequestsProps} items - Данные для рендера.
   * @param {string | number} selectedItem - Текущее выбранное значение.
   * @param {keyof PhotoSettings} itemType - Тип настройки (`costume`, `pose`, `set`).
   * @param {AiRequestsProps} [extraItems=[]] - Дополнительные элементы (например, `noSelectedItem`).
   * @returns {JSX.Element | null} Секция настроек или null, если данные пустые.
   */
  const renderSection = (
    index: number,
    items: AiRequestsProps,
    selectedItem: string | number,
    itemType: keyof PhotoSettings,
    extraItems: AiRequestsProps = [],
  ) =>
    !isEmpty(items) && (
      <div ref={setSectionRef(index)} className="grid grid-cols-4 gap-2">
        {renderFilterItems([...extraItems, ...items], selectedItem, itemType)}
      </div>
    );

  /**
   * Рендерит секцию с сетами.
   *
   * @returns {JSX.Element | null} Секция с сетами или null, если данные пустые.
   */
  const renderSetsSection = () =>
    !isEmpty(sets) && (
      <div ref={setSectionRef(2)} className="flex flex-col gap-2">
        <span className="text-lg font-normal">{t("settingsSetsNote")}</span>

        <div className="grid grid-cols-4 gap-2">
          {map(sets, ($set: AiRequestsQuery["getAiRequests"]["sets"]) =>
            renderFilterItems(
              [
                {
                  id: $set[0].id,
                  image: $set[0].image!,
                  position: 0,
                  category: 1,
                  subCategory: null,
                  icon: $set[0].icon,
                  name: $set[0].collection,
                  isFree: $set[0].isFree,
                  isTop: $set[0].isTop,
                  isNew: $set[0].isNew,
                  type: $set[0].type,
                  gender: $set[0].gender,
                  collection: $set[0].collection,
                  collectionTag: $set[0].collectionTag,
                },
              ],
              set,
              "set",
            ),
          )}
        </div>
      </div>
    );

  return (
    <div
      ref={wrapperRef}
      className="scroll-mask-y-4 scrollbar-y-2 flex flex-col gap-indent overflow-x-hidden"
      onScroll={handleScroll}
    >
      {renderSection(0, costumes, costume, "costume", [
        userPromt(t("prompt"), 0),
      ])}
      {renderSection(1, poses, pose, "pose")}
      {renderSetsSection()}
    </div>
  );
};
