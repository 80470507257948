"use client";

import { Box } from "@cloai/uikit";
import clsx from "clsx";
import Image from "next/image";
import { FC, memo } from "react";

import B from "./b.svg";
import G from "./g.svg";
import styles from "./LoaderCoff.module.scss";

type TLoaderCoffProps = {
  size?: number;
};
export const LoaderCoff: FC<TLoaderCoffProps> = memo(({ size = 64 }) => {
  const rx = size / 2;
  const border = size / 32;

  return (
    <Box center className={styles.box}>
      {/* фон */}
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={size} height={size} rx={rx} fill="#070518" />
      </svg>

      {/* девушка */}
      <Image src={G} alt="" width={size} height={size} className={styles.img} />

      {/* бикини */}
      <Image
        src={B}
        alt=""
        width={size}
        height={size}
        className={clsx(styles.img, styles.bikini)}
      />

      <div className={styles.line} />

      {/* обводка */}
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.img}
      >
        <rect
          x={border}
          y={border}
          width={size - border * 2}
          height={size - border * 2}
          rx={(size - border * 2) / 2}
          stroke="url(#paint0_linear_7825_103276)"
          strokeWidth={border * 2}
        />
        <defs>
          <linearGradient
            id="paint0_linear_7825_103276"
            x1={size / 2}
            y1="0"
            x2={size / 2}
            y2={size}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA516C" />
            <stop offset={border / 2} stopColor="#FDB365" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
});
LoaderCoff.displayName = "LoaderCoff";
