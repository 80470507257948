"use client";

import { Button, Icon, PickFace } from "@cloai/uikit";
import { includes, map } from "lodash";
import { useTranslations } from "next-intl";
import NextImage from "next/image";
import { FC, memo, ReactNode, useState } from "react";

import {
  AiRequestsProps,
  useAiRequestsContext,
  useUndressContext,
} from "@/providers";
import NoPoseImg from "@/styles/image/nopose.webp";
import PromptImg from "@/styles/image/prompt.webp";
import { cn } from "@/utils/cn";

/**
 * CSS классы для стилизации компонентов.
 */
const BASE_WRAPPER = cn(
  "relative flex max-h-[30dvh] w-full gap-2 text-center text-xl font-normal",
  "lg:max-h-full lg:items-center lg:justify-center lg:gap-6",
);
const WRAPPER_VARIANT = {
  double: cn(
    BASE_WRAPPER,
    "lg:px-indent",
    "lg:before:absolute lg:before:bottom-full lg:before:pb-[5dvh] lg:before:text-3xl lg:before:font-bold lg:before:content-[attr(data-title)]",
  ),
  mono: cn(BASE_WRAPPER, "h-full"),
};

const BASE_CARD = cn(
  "relative flex w-full items-center justify-center overflow-hidden rounded-2xl border-2 border-card-border bg-card",
  "lg:size-full lg:max-h-full lg:overflow-visible",
);
const CARD_VARIANT = {
  double: cn(
    BASE_CARD,
    "aspect-square",
    "lg:max-w-64",
    "lg:before:absolute lg:before:top-full lg:before:pt-3 lg:before:text-xl lg:before:content-[attr(data-title)]",
  ),
  mono: cn(BASE_CARD, "aspect-[406/199]", "lg:aspect-auto lg:border-0"),
};

/**
 * Компонент отображает блок предварительного просмотра настроек одежды и поз.
 * @component
 */
export const PreviewSettingsBlock: FC = () => {
  const t = useTranslations("HomePage.settingsPopup");
  const { costumes, poses, sets } = useAiRequestsContext();
  const {
    clickSettings,
    initialSettings,
    reUseFaceObj,
    selectedSetCount,
    updatePhotoSetting,
  } = useUndressContext();

  const { costume, pose, set } = clickSettings;
  const selectedList = [costume, pose, set];
  const isPromptSelected = costume === "prompt";
  const isPoseSelected = pose !== initialSettings.pose;
  const isSetSelected = set !== initialSettings.set;
  const variant = isSetSelected ? "mono" : "double";

  /**
   * Сбрасывает выбранную позу на изначальную.
   */
  const onResetPose = () => {
    updatePhotoSetting("initialSettings", "pose", initialSettings.pose);
  };

  return (
    <div className={WRAPPER_VARIANT[variant]} data-title={t("preview")}>
      <div className={CARD_VARIANT[variant]} data-title={t("settingsClothes")}>
        {isPromptSelected ? (
          <PlugImage
            image={PromptImg.src}
            text={
              <div className="flex flex-col items-center gap-1">
                {t("writePrompt")}
                <Icon icon="keyboard_hide" size={10} />
              </div>
            }
          />
        ) : (
          <RenderImage list={costumes} selectedList={selectedList} />
        )}
        <RenderImage list={sets} selectedList={selectedList} isFullscreen />

        {reUseFaceObj && (
          <div className="absolute top-3 left-3">
            <PickFace image={reUseFaceObj.file.fileUrl} onClick={() => null} />
          </div>
        )}

        {isSetSelected && (
          <div className="absolute top-4 right-4 flex items-center gap-2 text-2xl/6 font-medium">
            <span className="material-icons" data-icon="photo_camera" />
            <span>{selectedSetCount}</span>
          </div>
        )}
      </div>

      {!isSetSelected && (
        <div
          className={cn(
            CARD_VARIANT[variant],
            isPoseSelected && "cursor-pointer",
          )}
          data-title={t("settingsPose")}
          onClick={onResetPose}
        >
          {isPoseSelected ? (
            <>
              <RenderImage list={poses} selectedList={selectedList} />
              <div className="absolute top-0 right-0 drop-shadow-xs">
                <Button iconLeft="close" size="small" color="none" />
              </div>
            </>
          ) : (
            <PlugImage image={NoPoseImg.src} text={t("addSexPose")} />
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Компонент для рендеринга списка изображений.
 * @param {Object} props - Свойства компонента.
 * @param {AiRequestsProps} props.list - Список изображений.
 * @param {string[]} props.selectedList - Выбранные элементы.
 * @param {boolean} [props.isFullscreen] - Флаг полноэкранного режима.
 */
const RenderImage: FC<{
  list: Pick<AiRequestsProps[number], "id" | "image">[];
  selectedList: string[];
  isFullscreen?: boolean;
}> = ({ list, selectedList, isFullscreen }) =>
  map(list, ({ id, image }) =>
    image ? (
      <UIImage
        key={id}
        image={image}
        isShow={includes(selectedList, id)}
        isFullscreen={isFullscreen}
      />
    ) : null,
  );

/**
 * Компонент для отображения "заглушки" выбранной настройки (промт и позы).
 * @param {Object} props - Свойства компонента.
 * @param {string} props.image - URL изображения.
 * @param {string} props.image - Текст заглушки.
 */
const PlugImage: FC<{
  image: string;
  text: string | ReactNode;
}> = memo(({ image, text }) => (
  <>
    <UIImage image={image} isShow />
    <span
      className={cn(
        "absolute m-auto flex text-center text-xl font-medium whitespace-pre-line",
        "lg:text-2xl",
      )}
    >
      {text}
    </span>
  </>
));
PlugImage.displayName = "PlugImage";

/**
 * Компонент для отображения изображений с обработкой ошибок загрузки.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.image - URL изображения.
 * @param {boolean} [props.isShow] - Флаг отображения.
 * @param {boolean} [props.isFullscreen] - Флаг полноэкранного режима.
 */
const UIImage: FC<{ image: string; isShow?: boolean; isFullscreen?: boolean }> =
  memo(({ image, isShow, isFullscreen }) => {
    const [errorLoadingImage, setErrorLoadingImage] = useState(false);

    const onLoadedError = () => setErrorLoadingImage(true);

    const imageSize = isFullscreen ? 826 : 252;

    if (errorLoadingImage && isShow) {
      return (
        <span
          className="material-icons absolute inset-0 flex items-center justify-center opacity-20"
          data-icon="no_photography"
        />
      );
    }

    return (
      <NextImage
        src={image}
        width={imageSize}
        height={imageSize}
        quality={90}
        priority
        alt=""
        className={cn(
          "block size-full rounded-[0.875rem] object-contain",
          !isShow && "hide",
        )}
        onError={onLoadedError}
      />
    );
  });
UIImage.displayName = "UIImage";
