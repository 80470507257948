import { ActivatePromocodePortal } from "./activate-promocode";
import { BlockUserPortal } from "./block_user";
import { CaptchaPortal } from "./captcha";
import { ChangeLangPortal } from "./change-lang";
import { GiftRoadmapPortal } from "./gift-roadmap";
import { NewBalancePortal } from "./new-balance";
import { PasskeyPortal } from "./passkey";
import { PayHoldPortal } from "./pay_hold";
import { PwaPortal } from "./pwa";
import { RulesPortal } from "./rules";
import { SubscribesPortalFREE } from "./subscribes/free";
import { SubscribesPortalVIP } from "./subscribes/vip";
import { TutorPortal } from "./tutor";
import { TutorAnimePortal } from "./tutor-anime";
import { TutorBDSMPortal } from "./tutor-bdsm";
import { TutorHDPortal } from "./tutor-hd";
import { TutorPosePortal } from "./tutor-pose";
import { UploadVideoPortal } from "./upload-video";

export const GlobalPortals = () => (
  <>
    <ActivatePromocodePortal />
    {/* <AttachPhotoByInstaPortal />
    <AttachPhotoByUrlPortal /> */}
    <BlockUserPortal />
    <CaptchaPortal />
    <ChangeLangPortal />
    {/* <GenerationErrorPortal /> */}
    <SubscribesPortalVIP />
    <SubscribesPortalFREE />
    <GiftRoadmapPortal />
    <NewBalancePortal />
    <RulesPortal />
    <PasskeyPortal />
    <PayHoldPortal />
    <PwaPortal />
    <TutorAnimePortal />
    <TutorBDSMPortal />
    <TutorHDPortal />
    <TutorPortal />
    <TutorPosePortal />
    <UploadVideoPortal />
  </>
);
