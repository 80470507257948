"use client";

import { includes } from "lodash";
import { FC, useEffect, useMemo } from "react";

import { InputUndressType } from "@/.gql/graphql";
import { useProfileContext, useUndressContext } from "@/providers";
import { cn } from "@/utils/cn";

import { PlateCoinPacks } from "./InfoPlates";
import { PreviewSettingsBlock } from "./PreviewSettingsBlock";
import { UploadedPhotoBlock } from "./UploadedPhotoBlock";
import { VideoBlock } from "./VideoBlock";
import { VideoBlockResult } from "./VideoBlockResult";

export const PhotoBlock: FC = () => {
  const profile = useProfileContext();
  const {
    clickFileId,
    clickUndressingFile,
    clickUndressStatus,
    isOpenCustomizeSettings,
    setLoading,
    undressings,
    videoSettings,
  } = useUndressContext();

  const { isUndressing, isSuccess } = clickUndressStatus;

  /**
   * Находим текущий undress по `clickFileId`, если он есть в `undressings`.
   */
  const undress = useMemo(() => {
    if (!undressings) {
      return;
    }
    return undressings.find(({ id }) => id === clickFileId);
  }, [clickFileId, undressings]);

  /**
   * При появлении undress (когда загружено изображение),
   * снимаем состояние загрузки (`setLoading(false)`).
   * Это нужно, чтобы интерфейс понимал, что фото доступно для обработки/просмотра.
   */
  useEffect(() => {
    if (undress) {
      setLoading(false);
    }
  }, [setLoading, undress]);

  /**
   * Извлекаем текущий файл из undress или `clickUndressingFile`.
   * Если нет изображения, `fileUrl` будет `undefined`.
   */
  const fileUrl = useMemo(
    () => undress?.fileUrl ?? clickUndressingFile?.fileUrl,
    [clickUndressingFile?.fileUrl, undress?.fileUrl],
  );

  // TODO: добавить video в undress
  // const video = useMemo(
  //   () => undress?.video ?? videoSettings.video,
  //   [undress?.video, videoSettings.video],
  // );
  const video = videoSettings.video;

  /**
   * Дальше логика выбора контента
   */
  const renderComponent = () => {
    switch (true) {
      // Если нет коинов (isBlur) и есть файл, показываем плашку с рефералкой.
      // case noCoinsAndBlur && !!fileUrl:
      //   return <PlateGetFreeCoins />;

      // Для "Фришника" показывам коинпаки.
      case profile && undress?.isFree && isUndressing:
        return <PlateCoinPacks />;

      case isSuccess &&
        includes(
          [InputUndressType.Animation, InputUndressType.Video],
          undress?.undressType,
        ):
        return <VideoBlockResult />;

      case !!video:
        return <VideoBlock />;

      case !!fileUrl:
        return <UploadedPhotoBlock />;

      default:
        return <PreviewSettingsBlock />;
    }
  };

  return (
    <div
      className={cn(
        "relative flex items-center justify-center rounded-2xl",
        "lg:size-full lg:rounded-3xl lg:bg-card lg:p-3.5",
        "lg:before:pointer-events-none lg:before:absolute lg:before:inset-3 lg:before:rounded-2xl lg:before:border-2 lg:before:border-card-border",
        fileUrl && "mb-indent h-full overflow-hidden lg:mb-0",
        isOpenCustomizeSettings && "hide lg:flex", // скрываем блок на мобилках, когда открыты доп.настройки
      )}
    >
      {renderComponent()}
    </div>
  );
};

PhotoBlock.displayName = "PhotoBlock";
