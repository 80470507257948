import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";

const TIMER_INTERVAL = 1000;

interface TimerData {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  formattedDays: string;
  formattedHours: string;
  formattedMinutes: string;
  formattedSeconds: string;
}

const format = (time: number): string => _.padStart(String(time), 2, "0");

const calculateTime = (
  endDate: dayjs.Dayjs,
  currentDate: dayjs.Dayjs,
): TimerData => {
  const diff = endDate.diff(currentDate);
  const [days, hours, minutes, seconds] = [
    diff > 0 ? endDate.diff(currentDate, "day") : 0,
    diff > 0 ? endDate.diff(currentDate, "hours") % 24 : 0,
    diff > 0 ? endDate.diff(currentDate, "minutes") % 60 : 0,
    diff > 0 ? endDate.diff(currentDate, "seconds") % 60 : 0,
  ];

  return {
    days,
    hours,
    minutes,
    seconds,
    formattedDays: format(days),
    formattedHours: format(hours),
    formattedMinutes: format(minutes),
    formattedSeconds: format(seconds),
  };
};

export const useTimer = (
  date: Date | string,
  reverse = false,
): TimerData | null => {
  const [time, setTime] = useState<TimerData | null>(null);

  useEffect(() => {
    const endDate = reverse ? dayjs() : dayjs(date);
    const updateTime = () => {
      const timeData = calculateTime(reverse ? dayjs(date) : endDate, dayjs());
      setTime(timeData);
      if (
        timeData.days + timeData.hours + timeData.minutes + timeData.seconds ===
        0
      ) {
        clearInterval(interval);
      }
    };

    const interval = setInterval(updateTime, TIMER_INTERVAL);
    updateTime();

    return () => clearInterval(interval);
  }, [date, reverse]);

  return time;
};
