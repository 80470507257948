"use client";

import undressConfig from ".constants/undress.config.json";
import { cn, Icon, Textarea } from "@cloai/uikit";
import { isEmpty } from "lodash";
import { useTranslations } from "next-intl";
import { ChangeEvent, useEffect, useMemo, useState } from "react";

import { useUndressContext } from "@/providers";

export const PromtArea = () => {
  const t = useTranslations("HomePage");
  const {
    clickFileId,
    clickSettings,
    clickUndressStatus,
    initialSettings,
    undressingsFiles,
    updatePhotoSetting,
    videoSettings,
  } = useUndressContext();

  const { isInit } = clickUndressStatus;

  const isPromtSelected = useMemo(
    () =>
      clickSettings.costume === "prompt" &&
      isInit &&
      !videoSettings.video &&
      isEmpty(undressingsFiles),
    [clickSettings.costume, isInit, undressingsFiles, videoSettings.video],
  );

  const [isOpen, setOpen] = useState(true);
  const onToggleOpen = () => {
    setOpen(!isOpen);
  };

  const promptValue = clickSettings.prompt;

  const onWrite = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updatePhotoSetting(clickFileId, "prompt", event.target.value);
  };

  const onReset = () => {
    updatePhotoSetting(clickFileId, "prompt", initialSettings.prompt);
  };

  useEffect(() => {
    if (!isPromtSelected) {
      setTimeout(() => {
        setOpen(true);
      }, 300);
    }
  }, [isPromtSelected]);

  return (
    <div
      className={cn(
        "grid w-full grid-rows-[0fr] overflow-hidden transition-padding-grid-rows",
        isPromtSelected && "grid-rows-[1fr] pb-indent",
      )}
    >
      <div
        className={cn(
          "flex min-h-0 flex-col duration-300",
          !isPromtSelected && "pointer-events-none opacity-0",
          isPromtSelected && "delay-200",
        )}
      >
        <div
          className="group/prompt flex cursor-pointer items-center justify-between gap-indent select-none"
          onClick={onToggleOpen}
        >
          <span
            className={cn(
              "flex items-center gap-2 duration-200",
              "group-active/prompt:scale-98 group-active/prompt:duration-100",
            )}
          >
            <span>📝</span>
            {t("promtTextareaTitle")}
          </span>
          <Icon
            icon="keyboard_arrow_down"
            size={6}
            className={cn(
              "duration-200 lg:rotate-180",
              !isOpen && "rotate-180 lg:rotate-0",
            )}
          />
        </div>

        <div
          className={cn(
            "grid grid-rows-[0fr] overflow-hidden transition-padding-grid-rows",
            isOpen && "grid-rows-[1fr] pt-3",
          )}
        >
          <div
            className={cn(
              "min-h-0 duration-300",
              !isOpen && "pointer-events-none opacity-0",
              isOpen && "delay-200",
            )}
          >
            <Textarea
              placeholder={t("promtTextareaPlaceholder")}
              value={promptValue}
              onChange={onWrite}
              onReset={onReset}
              maxLength={undressConfig.UndressModifications.maxPromptLength}
              disabled={!isOpen || !isPromtSelected}
              className="!bg-[oklch(22.33%_0.0328_286.11)]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
