import { clamp } from "lodash";
import {
  ChangeEvent,
  Ref as ReactRef,
  RefObject,
  useCallback,
  useEffect,
} from "react";

import { calculatePercent } from "@/utils/calculatePercent";

/**
 * Тип пределов значений слайдера: [минимум, максимум].
 */
type Limit = [number, number];

/**
 * Тип значения слайдера: [minValue, maxValue].
 */
type SliderValue = [number, number];

/**
 * Тип, определяющий, какой ползунок мы двигаем: "start" (левый) или "end" (правый).
 */
type SliderType = "start" | "end";

interface UseRangeLogicParams {
  /**
   * Текущее значение слайдера: [minValue, maxValue].
   */
  value: SliderValue;

  /**
   * Предельные значения слайдера: [минимум, максимум].
   */
  limit: Limit;

  /**
   * Минимально допустимая разница между значениями.
   */
  minRange: number;

  /**
   * Максимально допустимая разница между значениями.
   */
  maxRange: number;

  /**
   * Колбэк, вызываемый при изменении значений слайдера.
   * Принимает массив [newMin, newMax].
   */
  onChangeValue: (newValue: SliderValue) => void;

  /**
   * Ссылка на элемент <video>, для синхронизации текущего времени видео.
   */
  videoRef: ReactRef<HTMLVideoElement>;

  /**
   * Ссылка на элемент контейнера слайдера.
   */
  sliderRef: ReactRef<HTMLDivElement>;

  /**
   * Ссылка на элемент, отображающий выделенную область между ползунками (range).
   */
  rangeRef: ReactRef<HTMLDivElement>;

  /**
   * Ссылка на элемент плашки (textPlate), который может отображать стоимость или другую информацию.
   */
  textPlateRef: ReactRef<HTMLDivElement>;
}

/**
 * Кастомный хук для логики управления двумя ползунками (start и end) диапазонного слайдера.
 *
 * Данный хук:
 * - Поддерживает ограничения минимальной и максимальной разницы между значениями (minRange и maxRange).
 * - Гарантирует, что при упоре в границы (limit) один из ползунков будет двигать другой для соблюдения условий.
 * - Синхронизирует текущее время воспроизведения видео, если передан videoRef.
 * - Обновляет стили элементов (slider, range, textPlate) при изменении value.
 *
 * @param {UseRangeLogicParams} params Параметры для логики слайдера.
 * @returns Функцию handleChangeCutPoints, которую можно использовать в onInput у ползунков.
 *
 * Пример использования:
 * ```typescript
 * const handleChangeCutPoints = useRangeLogic({
 *   value,
 *   limit,
 *   minRange,
 *   maxRange,
 *   onChangeValue,
 *   videoRef,
 *   sliderRef,
 *   rangeRef,
 *   textPlateRef
 * });
 *
 * <input type="range" onInput={handleChangeCutPoints("start")} ... />
 * <input type="range" onInput={handleChangeCutPoints("end")} ... />
 * ```
 *
 * Примечание:
 * В данный момент логика обновления стилей вынесена в useEffect.
 * Если потребуется, можно расформировать этот useEffect и вызывать логику обновления стилей
 * непосредственно в `handleChangeCutPoints`, но ничего не удаляйте из `handleChangeCutPoints`,
 * так как текущая логика работает идеально.
 */
export function useRangeLogic({
  value,
  limit,
  minRange,
  maxRange,
  onChangeValue,
  videoRef,
  sliderRef,
  rangeRef,
  textPlateRef,
}: UseRangeLogicParams) {
  const [minLimit, maxLimit] = limit;

  /**
   * Обработчик изменения значения слайдера.
   * В зависимости от того, какой ползунок двигается ("start" или "end"):
   * - Корректируем значения, чтобы разница не была меньше minRange и не была больше maxRange.
   * - Если достигаем пределов limit, подстраиваем противоположный ползунок.
   * - По итогам выставляем корректные startTime и endTime, вызываем onChangeValue.
   * - Синхронизируем videoElement.currentTime с изменённым значением.
   *
   * Важный момент: мы не мутируем props или event, а работаем с локальными переменными и результат
   * передаём наружу через onChangeValue. `event.target.value` меняется для синхронизации с DOM,
   * но это значение берётся из локальных вычислений.
   */
  const handleChangeCutPoints = useCallback(
    (type: SliderType) => (event: ChangeEvent<HTMLInputElement>) => {
      const val = Number(event.currentTarget.value);
      const videoElement = (videoRef as RefObject<HTMLVideoElement>).current;

      let [startTime, endTime] = value;

      if (type === "start") {
        // Двигаем левый ползунок
        startTime = val;
        let diff = endTime - startTime;

        // Проверяем минимально допустимую разницу
        if (diff < minRange) {
          // Разница слишком мала, двигаем правый вперёд
          endTime = startTime + minRange;
        } else if (diff > maxRange) {
          // Разница слишком велика, двигаем правый назад
          endTime = startTime + maxRange;
        }

        // Клэмпим значения, чтобы не выйти за пределы limit
        startTime = clamp(startTime, minLimit, maxLimit);
        endTime = clamp(endTime, minLimit, maxLimit);

        // Повторная проверка после клэмпа
        diff = endTime - startTime;
        if (diff < minRange) {
          if (endTime === maxLimit) {
            // Упёрлись в правый предел, двигаем левый назад
            startTime = endTime - minRange;
          } else {
            // Иначе двигаем правый вперёд
            endTime = startTime + minRange;
          }
        } else if (diff > maxRange) {
          if (startTime === minLimit) {
            // Упёрлись в левый предел
            endTime = startTime + maxRange;
          } else {
            endTime = startTime + maxRange;
          }
        }

        startTime = clamp(startTime, minLimit, maxLimit);
        endTime = clamp(endTime, minLimit, maxLimit);

        // Обновляем состояние
        onChangeValue([startTime, endTime]);

        // Синхронизируем видео
        if (videoElement) {
          videoElement.currentTime = startTime;
        }
      } else {
        // Двигаем правый ползунок
        endTime = val;
        let diff = endTime - startTime;

        if (diff < minRange) {
          // Разница слишком мала, двигаем левый назад
          startTime = endTime - minRange;
        } else if (diff > maxRange) {
          // Разница слишком велика, двигаем левый вперёд
          startTime = endTime - maxRange;
        }

        startTime = clamp(startTime, minLimit, maxLimit);
        endTime = clamp(endTime, minLimit, maxLimit);

        diff = endTime - startTime;
        if (diff < minRange) {
          if (startTime === minLimit) {
            // Упёрлись в левый предел
            endTime = startTime + minRange;
          } else {
            startTime = endTime - minRange;
          }
        } else if (diff > maxRange) {
          if (endTime === maxLimit) {
            // Упёрлись в правый предел
            startTime = endTime - maxRange;
          } else {
            startTime = endTime - maxRange;
          }
        }

        startTime = clamp(startTime, minLimit, maxLimit);
        endTime = clamp(endTime, minLimit, maxLimit);

        onChangeValue([startTime, endTime]);

        if (videoElement) {
          videoElement.currentTime = endTime;
        }
      }
    },
    [videoRef, value, minRange, maxRange, minLimit, maxLimit, onChangeValue],
  );

  const sliderElement = (sliderRef as RefObject<HTMLDivElement>).current;
  const rangeElement = (rangeRef as RefObject<HTMLDivElement>).current;
  const textPlateElement = (textPlateRef as RefObject<HTMLDivElement>).current;

  /**
   * При изменении значения обновляем стили слайдера:
   * - Маска slider для отображения текущего положения
   * - Позиция и ширина выделенной области (range)
   * - Позиция плашки со стоимостью генерации (textPlate)
   *
   * В случае необходимости, эту логику можно перенести внутрь handleChangeCutPoints,
   * но сейчас она идеально работает здесь. Мы ничего не удаляем из handleChangeCutPoints,
   * чтобы не нарушить её логику.
   */
  useEffect(() => {
    const minPercent = calculatePercent(value[0], limit);
    const maxPercent = calculatePercent(value[1], limit);

    if (sliderElement) {
      // eslint-disable-next-line react-compiler/react-compiler
      sliderElement.style.maskImage = `linear-gradient(to right, rgba(255,255,255,0.5) 0 ${minPercent}%, black ${minPercent}% ${maxPercent}%, rgba(255,255,255,0.5) ${maxPercent}% 100%)`;
    }

    if (rangeElement) {
      rangeElement.style.left = `${minPercent}%`;
      rangeElement.style.width = `${maxPercent - minPercent}%`;
    }

    if (textPlateElement) {
      // Центрируем textPlate между ползунками, с ограничениями по краям
      textPlateElement.style.left = `clamp(20px, calc(${(maxPercent - minPercent) / 2 + minPercent}% - 50px), calc(100% - 120px))`;
    }
  }, [limit, rangeElement, sliderElement, textPlateElement, value]);

  return handleChangeCutPoints;
}
