"use client";

import undressConfig from ".constants/undress.config.json";
import { ClothFilterItem, ClothFilterRoundButton } from "@cloai/uikit";
import { includes, some } from "lodash";
import { useLocale } from "next-intl";

import { Gender } from "@/.gql/graphql";
import {
  type AiRequestsProps,
  type PhotoSettings,
  useGiftContext,
  useProfileContext,
  useUndressContext,
} from "@/providers";
import GiftIcon from "@/styles/image/gift-icon.webp";

import { useClickSettings } from "./useClickSettings";

/**
 * Хук для рендера элементов фильтра (настроек).
 * @returns Функции `renderFilterItems` и `noSelectedItem`
 */
export const useRenderItem = () => {
  const profile = useProfileContext();
  const { clickUndressingFile } = useUndressContext();
  const { claimedUndressGift } = useGiftContext();

  const locale = useLocale();
  const handleItemClick = useClickSettings();

  const haveVipCoins =
    (profile && profile?.coins >= undressConfig.undressCost) ?? false;

  /**
   * Определяет свойства элемента фильтра.
   * @param item - Элемент настройки
   * @param itemType - Тип настройки (`gender`, `quality`, `costume`, `pose`, и т. д.)
   * @returns Объект с `isGift`, `isGold`, `isVIP`, `isNew` и `variant`
   */
  const getItemProperties = (
    item: AiRequestsProps[0],
    itemType: keyof PhotoSettings,
  ) => {
    const isGift = some(
      claimedUndressGift,
      (gift) =>
        (gift.__typename === "CostumeUserBonus" &&
          gift.costumeId === item.id) ||
        (gift.__typename === "PoseUserBonus" && gift.poseId === item.id),
    );

    return {
      isGift,
      isGold: item.isTop && !isGift,
      isVIP: !haveVipCoins && !item.isFree,
      isNew: item.isNew,
      variant: includes(["costume", "pose", "set"], itemType)
        ? ("text" as const)
        : ("image" as const),
    };
  };

  /**
   * Рендерит `ClothFilterRoundButton` для определённых типов (`gender`, `age`, `quality`).
   * @param item - Элемент настройки
   * @param itemType - Тип настройки
   * @param state - Текущее выбранное состояние
   * @param disabled - Отключён ли элемент
   * @returns Компонент `ClothFilterRoundButton`
   */
  const renderRoundButtonItem = (
    item: AiRequestsProps[0],
    itemType: keyof PhotoSettings,
    state: string | number,
    disabled?: boolean,
  ) => {
    const { isVIP } = getItemProperties(item, itemType);
    return (
      <ClothFilterRoundButton
        key={item.id}
        text={item.name[locale] || item.name["en"] || item}
        active={state === (item.id || item)}
        data-item={item.id || item}
        onClick={handleItemClick(itemType, item.id)}
        badgeIcon={isVIP ? "diamond" : ""}
        disabled={disabled}
      />
    );
  };

  /**
   * Рендерит `ClothFilterItem` для других типов (`costume`, `pose`, `set` и т. д.).
   * @param item - Элемент настройки
   * @param itemType - Тип настройки
   * @param state - Текущее выбранное состояние
   * @param disabled - Отключён ли элемент
   * @returns Компонент `ClothFilterItem`
   */
  const renderFilterItem = (
    item: AiRequestsProps[0],
    itemType: keyof PhotoSettings,
    state: string | number,
    disabled?: boolean,
  ) => {
    const { isGift, isGold, isVIP, isNew, variant } = getItemProperties(
      item,
      itemType,
    );

    return (
      <ClothFilterItem
        key={item.id}
        image={variant === "image" ? item.image! : (item.icon ?? "🆘")}
        text={item.name[locale] || item.name["en"]}
        active={state === item.id}
        data-item={item.id}
        variant={variant}
        wide={variant === "text"}
        onClick={handleItemClick(itemType, item.id)}
        isGift={isGift}
        isGold={isGold}
        isNew={isNew}
        badgeIcon={isGift ? GiftIcon : isVIP ? "diamond" : ""}
        disabled={disabled}
      />
    );
  };

  /**
   * Рендерит список фильтров в зависимости от `itemType`.
   * @param list - Список элементов фильтра
   * @param state - Текущее состояние
   * @param itemType - Тип настройки (`gender`, `quality`, `costume` и т. д.)
   * @param disabled - Отключены ли элементы
   * @returns Список компонентов `ClothFilterRoundButton` или `ClothFilterItem`
   */
  const renderFilterItems = (
    list: AiRequestsProps,
    state: string | number,
    itemType: keyof PhotoSettings,
    disabled?: boolean,
  ) =>
    list.map((item) =>
      includes(["gender", "age", "quality"], itemType)
        ? renderRoundButtonItem(item, itemType, state, disabled)
        : renderFilterItem(item, itemType, state, disabled),
    );

  /**
   * Создаёт "пустой" элемент для фильтра (по умолчанию).
   * @param name - Текстовое название элемента
   * @param category - Категория элемента
   * @returns Объект с базовыми свойствами фильтра
   */
  const noSelectedItem = (name: string, category: number) => ({
    id: "default",
    image: clickUndressingFile?.fileUrl ?? "",
    position: 0,
    category,
    subCategory: null,
    icon: "🙅‍♂️",
    name: {
      [locale]: name,
    },
    isFree: true,
    isTop: false,
    isNew: false,
    type: 0,
    gender: Gender.Male,
    collection: null,
    collectionTag: null,
  });

  /**
   * Создаёт элемент "ПРОМТ" для фильтра.
   * @param name - Текстовое название элемента
   * @param category - Категория элемента
   * @returns Объект с базовыми свойствами фильтра
   */
  const userPromt = (name: string, category: number) => ({
    id: "prompt",
    image: "",
    position: 0,
    category,
    subCategory: null,
    icon: "📝",
    name: {
      [locale]: name,
    },
    isFree: false,
    isTop: true,
    isNew: true,
    type: 0,
    gender: Gender.Unisex,
    collection: null,
    collectionTag: null,
  });

  return { renderFilterItems, noSelectedItem, userPromt };
};
