"use client";

import { Box, Text } from "@cloai/uikit";
import clsx from "clsx";
import { FC, memo, PropsWithChildren, useRef, Ref as ReactRef } from "react";

import styles from "./RangeControl.module.scss";
import { usePlayLogic } from "./usePlayLogic";
import { useRangeLogic } from "./useRangeLogic";

/**
 * Тип пропсов для компонента RangeControl.
 */
type TRangeControlProps = PropsWithChildren & {
  /**
   * Тема оформления слайдера:
   * - "none" — без фона,
   * - "with_bg" — с фоновым заливом.
   * По умолчанию "none".
   */
  theme?: "none" | "with_bg";

  /**
   * Лимит доступных значений: [минимум, максимум].
   */
  limit: [number, number];

  /**
   * Текущее значение слайдера: [minValue, maxValue].
   * minValue - левый ползунок, maxValue - правый ползунок.
   */
  value: [number, number];

  /**
   * Минимально допустимая разница между minValue и maxValue.
   * По умолчанию: 1
   */
  minRange?: number;

  /**
   * Максимально допустимая разница между minValue и maxValue.
   * По умолчанию равна value[1].
   */
  maxRange?: number;

  /**
   * Шаг изменения значения.
   * По умолчанию: 0.5.
   */
  step?: number;

  /**
   * Колбэк, вызываемый при изменении значений слайдера.
   * Принимает массив [newMin, newMax].
   */
  onChangeValue: (newValue: [number, number]) => void;

  /**
   * Стоимость генерации (опциональное поле).
   * Если указана, будет отображена плашка с данной стоимостью.
   */
  undressingCost?: number;

  /**
   * Дополнительный ref для элемента <video>, позволяющий управлять видео извне компонента.
   */
  videoRef: ReactRef<HTMLVideoElement>;

  /**
   * Если true — слайдер в состоянии загрузки (неактивен).
   */
  loading?: boolean;

  /**
   * Если true — слайдер неактивен (disabled).
   */
  disabled?: boolean;

  /**
   * Если true — отображается состояние ошибки.
   */
  error?: boolean;

  /**
   * Дополнительный CSS-класс для контейнера.
   */
  extraClass?: string;
};

/**
 * Компонент RangeControl — двухползунковый слайдер для выбора диапазона значений.
 *
 * Он контролирует:
 * - Минимальную (minRange) и максимальную (maxRange) разницу между значениями.
 * - Корректирует позицию противоположного ползунка при выходе за пределы заданных разниц.
 * - При упоре в границы (limit) также подстраивает значения.
 *
 * Также, если передан videoRef, синхронизирует текущее время воспроизведения видео с изменяемым значением.
 *
 * Плашка (textPlate), если undressingCost указана, показывает стоимость на треке, центрируясь между ползунками.
 *
 * В данный момент вся необходимая логика вынесена во внешние хуки `useRangeLogic` и `usePlayLogic`, которые обеспечивают:
 * - useRangeLogic: логику корректировки ползунков и обновления стилей.
 * - usePlayLogic: логику воспроизведения видео в заданном диапазоне, останавливая и перезапуская при достижении конца.
 */
export const RangeControl: FC<TRangeControlProps> = memo(
  ({
    theme = "none",
    limit,
    value,
    minRange = 1,
    maxRange = value[1],
    step = 0.5,
    onChangeValue,
    undressingCost,
    loading,
    videoRef,
    disabled,
    error,
    extraClass,
    children,
    ...props
  }) => {
    /** Ссылки на элементы слайдера */
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const rangeRef = useRef<HTMLDivElement | null>(null);
    const textPlateRef = useRef<HTMLDivElement | null>(null);

    /**
     * Функция для форматирования чисел в компактный формат.
     * Например, 1200 -> "1.2K".
     */
    const internationalFormatNum = (n: number | undefined): string => {
      if (n !== undefined) {
        return new Intl.NumberFormat("en", {
          notation: "compact",
          maximumFractionDigits: 2,
        }).format(n);
      }
      return "0";
    };

    // Продолжительность (разница между minValue и maxValue) в секундах, форматированная.
    const duration = internationalFormatNum(value[1] - value[0]);

    /**
     * Хук useRangeLogic возвращает обработчик handleChangeCutPoints,
     * который контролирует логику корректировки ползунков и обновления стилей.
     */
    const handleChangeCutPoints = useRangeLogic({
      value,
      limit,
      minRange,
      maxRange,
      onChangeValue,
      videoRef,
      sliderRef,
      rangeRef,
      textPlateRef,
    });

    /**
     * Хук usePlayLogic обеспечивает логику воспроизведения видео в заданном диапазоне,
     * отслеживает текущее время (playLineTimelie), а также состояние перетаскивания (isDraging).
     */
    const { playLineTimelie, isDraging, onDraging } = usePlayLogic({
      value,
      videoRef,
    });

    return (
      <Box
        row
        wide
        center
        className={clsx(styles.range_box, styles[theme], extraClass)}
        {...props}
      >
        {/** Инпут для минимального значения (левый ползунок) */}
        <input
          type="range"
          min={limit[0]}
          max={limit[1]}
          value={value[0]}
          step={step}
          disabled={disabled || loading}
          onInput={handleChangeCutPoints("start")} // При движении левого ползунка вызываем handleChangeCutPoints("start")
          onMouseDown={onDraging(true)} // Устанавливаем состояние перетаскивания при зажатии мыши
          onMouseUp={onDraging(false)} // Сбрасываем состояние при отпускании мыши
          className={clsx(styles.thumb, styles.left)}
          style={{
            zIndex:
              value[0] >= (limit[1] - limit[0]) / 2 + limit[0] ? "3" : "2",
          }}
        />

        {/** Инпут для отображения текущего положения playLineTimelie.
         *  Этот ползунок используется только для отображения прогресса воспроизведения (readOnly),
         *  и скрывается (styles._hide) при перетаскивании пользовательских ползунков.
         */}
        <input
          type="range"
          min={limit[0]}
          max={limit[1]}
          value={playLineTimelie}
          step={0.01}
          disabled={disabled || loading}
          readOnly // Только для отображения
          className={clsx(styles.thumb, styles.play_line, {
            [styles._hide]: isDraging, // Скрываем при перетаскивании
          })}
        />

        {/** Инпут для максимального значения (правый ползунок) */}
        <input
          type="range"
          min={limit[0]}
          max={limit[1]}
          value={value[1]}
          step={step}
          disabled={disabled || loading}
          onInput={handleChangeCutPoints("end")} // При движении правого ползунка handleChangeCutPoints("end")
          onMouseDown={onDraging(true)} // Перетаскивание начато
          onMouseUp={onDraging(false)} // Перетаскивание окончено
          className={clsx(styles.thumb, styles.right)}
        />

        {/** Контейнер слайдера, содержащий трек и выделенную область */}
        <Box row align_center ref={sliderRef} className={styles.slider}>
          <div className={styles.track} /> {/** Трек слайдера (фон) */}
          <div ref={rangeRef} className={styles.range} />{" "}
          {/** Выделенная область */}
          {children}
        </Box>

        {/** Плашка со стоимостью, если указана undressingCost */}
        {undressingCost && (
          <Box
            ref={textPlateRef}
            center
            className={clsx(styles.cost, { [styles.error]: error })}
          >
            <Text
              text={
                <Box gap={2} row align_center>
                  {duration} sec. = {undressingCost}
                  <span
                    className="material-icons"
                    data-icon="diamond"
                    style={{ fontSize: "inherit" }}
                  />
                </Box>
              }
              fontSize={10}
              fontWeight={500}
              className={styles.text}
            />
          </Box>
        )}
      </Box>
    );
  },
);

RangeControl.displayName = "RangeControl";
