import Image from "next/image";
import { FC, HTMLAttributes, memo, useMemo } from "react";

import { LoaderCoff } from "@/components/loader/LoaderCoff";
import LogoDeepnudeai from "@/styles/image/logo-deepnudeai.svg";
import LogoNudiva from "@/styles/image/logo-nudiva.svg";
import { cn } from "@/utils/cn";

/**
 * Компонент `LoaderByHost` отображает логотип или лоадер в зависимости от хоста (доменного имени).
 *
 * Логика:
 * - Если `host` содержит "nudiva", отображаем логотип Nudiva.
 * - Если `host` содержит "deepnudeai", отображаем логотип Deepnudeai.
 * - Иначе отображаем дефолтный лоадер (`LoaderCoff`).
 *
 * Данный компонент может использоваться, чтобы брендировать процесс загрузки
 * для разных доменов или сайтов, работающих на одном коде.
 *
 * @component
 * @example
 * ```typescript
 * <LoaderByHost host="example.nudiva.com" />
 * ```
 *
 * @param {object} props Свойства компонента.
 * @param {string} props.host Строка, содержащая хост (доменное имя).
 * @returns {JSX.Element} Логотип или лоадер в зависимости от хоста.
 */

type TLoaderByHostProps = HTMLAttributes<HTMLDivElement> & {
  host: string;
  fullscreen?: boolean;
};
export const LoaderByHost: FC<TLoaderByHostProps> = memo(
  ({ host, fullscreen, className, ...props }) => {
    const clx = fullscreen
      ? "flex h-dvh w-full items-center justify-center"
      : "";
    /**
     * С помощью useMemo вычисляем, какой лоадер или логотип отображать:
     * - Проверяем содержимое host на включение строк "nudiva" или "deepnudeai.net".
     * - Возвращаем соответствующий JSX.
     */
    const loader = useMemo(() => {
      switch (true) {
        case host.includes("nudiva"):
          // Если host содержит "nudiva", показываем логотип Nudiva
          return (
            <div className={cn(clx, className)} {...props}>
              <Image
                src={LogoNudiva}
                width={64}
                height={64}
                alt={`${host} logo`}
              />
            </div>
          );

        case host.includes("deepnudeai.net"):
          // Если host содержит ".net", показываем логотип Deepnudeai
          return (
            <div className={cn(clx, className)} {...props}>
              <Image
                src={LogoDeepnudeai}
                width={64}
                height={64}
                alt={`${host} logo`}
              />
            </div>
          );

        default:
          // Иначе используем дефолтный лоадер LoaderCoff
          return (
            <div className={cn(clx, className)} {...props}>
              <LoaderCoff />
            </div>
          );
      }
    }, [className, clx, host, props]);

    return loader;
  },
);

LoaderByHost.displayName = "LoaderByHost";
