"use client";

import { GE } from ".constants/gtmEvents";
import { PORTALS } from ".constants/portals";
import {
  Icon,
  ModalContent,
  PortalModal,
  Text,
  usePortalContext,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { CSSProperties, FC, useEffect, useRef } from "react";

import { BurgerSidebarProps } from "@/components/burger-menu/BurgerSidebar";
import { SidebarHeader } from "@/components/sidebar/SidebarHeader";

import { ANCHORS } from "../../const";
import { LangButton } from "../LangButton";

export const BurgerSidebar: FC<BurgerSidebarProps> = ({ promo }) => {
  const t = useTranslations("ClothoffLanding");
  const { currentPortal, closePortal } = usePortalContext();

  const burgerRef = useRef<HTMLDivElement>(null);

  // Закрытие меню при клике на любой элемент внутри него
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (burgerRef.current?.contains(event.target as Node)) {
        closePortal(); // Закрываем меню, если клик был внутри
      }
    };

    if (currentPortal === PORTALS.BurgerMenu) {
      document.addEventListener("click", handleClick);
    }

    return () => document.removeEventListener("click", handleClick);
  }, [closePortal, currentPortal]);

  return (
    <>
      <PortalModal
        portalId={PORTALS.BurgerMenu}
        onClose={closePortal}
        variant="right"
        style={
          {
            "--color-popup": "var(--color-clothoff-landing-primary)",
          } as CSSProperties
        }
      >
        <div ref={burgerRef} className="flex h-full flex-col gap-popup-gap">
          <SidebarHeader promo={promo} />
          <ModalContent className="gap-3">
            <hr />

            <nav className="flex flex-col">
              {Object.values(ANCHORS).map((anchor, index) => (
                <Link
                  key={anchor}
                  href={`#${anchor}`}
                  className="flex items-center justify-between py-3"
                >
                  <Text
                    text={t(`nav.clause${index + 1}` as never)}
                    color="white"
                    fontSize={18}
                  />
                  <Icon icon="keyboard_arrow_right" />
                </Link>
              ))}
            </nav>

            <hr />

            <LangButton variant="mobile" event={GE.CHOOSE_LANGUAGE_BURGER} />
          </ModalContent>
        </div>
      </PortalModal>
    </>
  );
};
