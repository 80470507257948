"use client";

import { Text, TextLink } from "@cloai/uikit";

import { useFooterLinks } from "../hooks/useFooterLinks";

export const FooterLinks = () => {
  const footerLinks = useFooterLinks();

  return footerLinks.common.map((link) => {
    if (link?.hide) {
      return null;
    }

    if (!link.href) {
      return (
        <Text
          key={link.text}
          text={link.text}
          onClick={link?.onClick}
          className="cursor-pointer text-neutral-300! transition-colors hover:text-foreground!"
        />
      );
    }

    return (
      <TextLink
        key={link.text}
        href={link.href}
        text={link.text}
        className="text-neutral-300! transition-colors hover:text-foreground!"
      />
    );
  });
};

export const FooterPrelands = () => {
  const footerLinks = useFooterLinks();

  return footerLinks.prelands.map((preland) => (
    <TextLink
      key={preland.text}
      href={preland.href}
      text={preland.text}
      className="text-neutral-300! transition-colors hover:text-foreground!"
    />
  ));
};
