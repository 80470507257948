"use client";

import { PORTALS } from ".constants/portals";
import { usePortalContext } from "@cloai/uikit";
import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";

import { useGiftContext, useProfileContext } from "@/providers";

export const MountEffect = () => {
  const profile = useProfileContext();
  const { currentPortal, openPortal } = usePortalContext();
  const { hasToClaimGift } = useGiftContext();
  const [cookies] = useCookies([
    "tutorReaded",
    "app",
    "newBalance",
    "passkeyDontShow",
    "giftPopupIsShowed",
    "tutorPoseIsReaded",
  ]);

  // NOTE: Туториал по качеству загружаемых фото
  const tutorOpenedRef = useRef(false);
  useEffect(() => {
    if (tutorOpenedRef.current) {
      return;
    }

    if (!profile) {
      if (cookies.app === 1 && cookies.tutorReaded !== 1) {
        tutorOpenedRef.current = true;
        openPortal(PORTALS.Tutor);
      }
      return;
    }
  }, [cookies.app, cookies.tutorReaded, openPortal, profile]);

  // NOTE: попап для "вернувшихся" про новый баланс
  const newBalanceOpenedRef = useRef(false);
  useEffect(() => {
    if (newBalanceOpenedRef.current) {
      return;
    }

    if (
      cookies.newBalance !== undefined &&
      currentPortal !== PORTALS.NewBalance
    ) {
      newBalanceOpenedRef.current = true; // Фиксируем, что попап открыт
      openPortal(PORTALS.NewBalance);
    }
  }, [cookies.newBalance, currentPortal, openPortal]);

  // NOTE: попап карты бонусов
  const giftRef = useRef(false);
  useEffect(() => {
    if (giftRef.current) {
      return;
    }

    if (
      !cookies.giftPopupIsShowed &&
      hasToClaimGift &&
      currentPortal !== PORTALS.GiftRoadmap
    ) {
      openPortal(PORTALS.GiftRoadmap);
    }
  }, [cookies.giftPopupIsShowed, currentPortal, hasToClaimGift, openPortal]);

  // NOTE: Туториал по позам
  const tutorPoseIsReaded = Number(cookies.tutorPoseIsReaded ?? 0) === 1;
  const tutorPoseOpenedRef = useRef(false);
  useEffect(() => {
    if (
      tutorPoseOpenedRef.current || // Уже открывали попап? Выходим
      tutorPoseIsReaded ||
      !profile?.isWasFirstUndress ||
      currentPortal === PORTALS.TutorPose
    ) {
      return;
    }

    tutorPoseOpenedRef.current = true; // Фиксируем, что попап открыт
    openPortal(PORTALS.TutorPose);
  }, [
    currentPortal,
    openPortal,
    profile?.isWasFirstUndress,
    tutorPoseIsReaded,
  ]);

  return null;
};
