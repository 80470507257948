"use client";

import { PORTALS } from ".constants/portals";
import { usePortalContext } from "@cloai/uikit";
import { SyntheticEvent } from "react";
import { useCookies } from "react-cookie";

import {
  PhotoSettings,
  useAiRequestsContext,
  useUndressContext,
} from "@/providers";

/**
 * Хук для обработки кликов по настройкам фото и открытия туториалов.
 * @returns Функция `handleItemClick`, которую можно передавать в `onClick`
 */
export const useClickSettings = () => {
  const { openPortal } = usePortalContext();
  const { updatePhotoSetting, clickFileId } = useUndressContext();
  const { qualities } = useAiRequestsContext();

  // Получаем cookie-флаги, прочитан ли туториал
  const [cookies] = useCookies(["tutorHdIsReaded", "tutorArtIsReaded"]);
  const tutorHdIsReaded = Number(cookies.tutorHdIsReaded) === 1;
  const tutorArtIsReaded = Number(cookies.tutorArtIsReaded) === 1;

  /**
   * Открывает портал (модальное окно) с туториалом.
   * @param event - Событие клика
   * @param portalId - ID портала
   */
  const handlePortal = (event: SyntheticEvent, portalId: string) => {
    event.stopPropagation();
    event.preventDefault();
    openPortal(portalId);
  };

  /**
   * Обработчик клика по настройке фото.
   * @param itemType - Тип настройки (`quality`, `pose`, `costume` и т. д.)
   * @param itemId - ID выбранной настройки
   * @returns Функция обработчика `onClick`
   */
  const handleItemClick =
    (itemType: keyof PhotoSettings, itemId: string) =>
    (event: SyntheticEvent) => {
      updatePhotoSetting(clickFileId, itemType, itemId);

      // Проверяем, нужно ли открыть туториал
      if (itemType === "quality") {
        const name = qualities.find((i) => i.id === itemId)?.name["en"];

        // Словарь с туториалами и условиями их показа
        const tutorialPortals: Record<
          string,
          { url: string; isRead: boolean }
        > = {
          HD: { url: PORTALS.TutorHD, isRead: tutorHdIsReaded },
          ART: { url: PORTALS.TutorAnime, isRead: tutorArtIsReaded },
        };

        // Если туториал существует и не был прочитан, открываем
        if (name && tutorialPortals[name] && !tutorialPortals[name].isRead) {
          handlePortal(event, tutorialPortals[name].url);
        }
      }
    };

  return handleItemClick;
};
