import { useQuery } from "@apollo/client";

import { GET_UNDRESSINGS } from "@/.graphql";
import { useUndressContext } from "@/providers";

export const Position = ({ isVideo }: { isVideo?: boolean }) => {
  const interval = isVideo ? 15000 : 2000;
  const { clickFileId } = useUndressContext();
  const { data } = useQuery(GET_UNDRESSINGS, {
    pollInterval: interval,
  });

  if (!data?.getUndressings) {
    return null;
  }

  return data.getUndressings.find((undressing) => undressing.id === clickFileId)
    ?.position;
};
