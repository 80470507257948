"use client";

import { GE } from ".constants/gtmEvents";
import _ from "lodash";
import { useTranslations } from "next-intl";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import {
  useGTMContext,
  useProfileContext,
  useUndressContext,
} from "@/providers";
import { generateUUID } from "@/utils/crypto";
import { fileToBase64 } from "@/utils/fileToBase64";
import { createScreenshots } from "@/utils/getVideoFrames";

export const MAX_UPLOADED_VIDEO_DURATION = 30; // максимально допустимая длительность видео в секундах для загрузки
export const MAX_CUT_VIDEO_DURATION = 5; // максимально допустимая длительность видео в секундах для генерации

/**
 * Хук `useDropzoneSettings` конфигурирует и возвращает пропсы для
 * двух дропзон:
 * 1. Дропзона для загрузки фотографий.
 * 2. Дропзона для загрузки видео.
 *
 * Он интегрирован с контекстом приложения, обновляет состояние и настройки
 * загруженного контента (фото/видео), а также отображает уведомления при ошибках.
 *
 * @returns {object} Объект с параметрами для фото- и видеодропзон.
 */
export const useDropzoneSettings = () => {
  /**
   * Хук для получения функции перевода сообщений.
   * Используем namespace "HomePage.toast" для переводов ошибок.
   */
  const t = useTranslations("HomePage.toast");

  /** Данные о профиле */
  const profile = useProfileContext();

  /** GTM-контекст для отправки аналитики */
  const { sendGTM } = useGTMContext();

  /** Контекст обработки "раздевания" (undress), содержащий состояние и настройки медиа */
  const {
    clickFileId,
    clickSettings,
    initialSettings,
    maxFilesPhoto,
    setClickFileId,
    setLoading,
    setSettings,
    setUndressingsFiles,
    undressings,
    undressingsFiles,
    updateVideoSetting,
    videoSettings,
  } = useUndressContext();

  /**
   * Определяем текущий объект "undress" на основе выбранного `clickFileId`.
   * Если `undressings` нет или пуст, вернётся undefined.
   */
  const undress = useMemo(() => {
    if (!undressings) {
      return;
    }
    return undressings.find(({ id }) => id === clickFileId);
  }, [clickFileId, undressings]);

  /**
   * Загружает принятые файлы, конвертирует их в Base64 и сохраняет в состоянии.
   * Также обновляет настройки для новых файлов.
   *
   * @param {File[]} acceptedFiles Массив принятых файлов (изображений).
   */
  const setNewFiles = async (acceptedFiles: File[]) => {
    const convertedFiles = (
      await Promise.all(acceptedFiles.map((file) => fileToBase64(file)))
    ).map((fileUrl) => ({ id: generateUUID(), fileUrl }));

    if (convertedFiles.length === 0) {
      return;
    }

    // Обновляем состояние с массивом изображений
    setUndressingsFiles([...undressingsFiles, ...convertedFiles]);

    // Обновляем настройки для каждого нового изображения
    setSettings((prevSettings) => {
      if (undressingsFiles.length === 0) {
        // Если раньше не было изображений, используем clickSettings
        return convertedFiles.map(({ id }) => ({
          ...clickSettings,
          id,
        }));
      }

      // Если были изображения, добавляем новые с initialSettings
      return [
        ...prevSettings,
        ...convertedFiles.map(({ id }) => ({
          ...initialSettings,
          id,
        })),
      ];
    });

    // Выбираем последнее добавленное изображение в качестве активного
    setClickFileId(convertedFiles[convertedFiles.length - 1].id);
  };

  /**
   * Настройки дропзоны для загрузки фотографий:
   * - Принимаем только изображения jpeg, png
   * - Макс. количество файлов зависит от профиля (гость — 1, юзер — до 9 с учётом уже загруженных)
   * - При превышении лимита или при ошибке отображается toast с ошибкой "maxLimit"
   */
  const availableQty = useMemo(
    () => maxFilesPhoto - undressingsFiles.length,
    [undressingsFiles.length, maxFilesPhoto],
  );

  const { getRootProps: getRootPhotoProps, getInputProps: getInputPhotoProps } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".png"],
      },
      maxFiles: availableQty,
      multiple: !!profile || maxFilesPhoto !== 1,
      disabled: !!undress,
      maxSize: 30 * 1024 * 1024,
      onDropRejected: (fileRejections) => {
        // Обрабатываем отклонённые файлы, сохраняем их в стейт как изображения
        // для консистентности или отображения
        setNewFiles(
          _(fileRejections)
            .take(availableQty)
            .map(({ file }) => file)
            .value(),
        );

        // Показываем уведомление об ошибке (превышен лимит)
        return toast.error(t("maxLimit"));
      },
      onDrop: async (acceptedFiles: File[]) => {
        // Событие для GTM-аналитики
        sendGTM({ event: GE.UPLOAD_PHOTO__UPLOADBLOCK });

        // Сохраняем загруженные фото
        setNewFiles(acceptedFiles);
      },
    });

  /**
   * Настройки дропзоны для загрузки видео:
   * - Принимаем только видеофайлы некоторых форматов
   * - Не более 1 файла
   * - При загрузке вычисляем длительность и кадры
   * - Если длительность превышает MAX_VIDEO_DURATION, выбрасываем ошибку и отображаем toast
   */
  const { getRootProps: getRootVideoProps, getInputProps: getInputVideoProps } =
    useDropzone({
      accept: {
        "video/*": [".mp4", ".mpeg", ".ogg", ".mov"],
      },
      maxFiles: 1,
      multiple: false,
      disabled: !!undress,
      onDrop: async (acceptedFiles: File[]) => {
        setLoading(true);

        /** Первый загруженный файл предполагается видео */
        const file = acceptedFiles[0];

        /** Создаём временный URL для файла */
        const videoUrl = URL.createObjectURL(file);

        /** Обновляем URL видео в настройках */
        updateVideoSetting("video", videoUrl);

        /** Рассчитываем количество скриншотов, исходя из ширины окна */
        const w = window.innerWidth;
        const screenshotsCount = Math.round((w <= 920 ? w : w - 576) / 64);

        try {
          // Получаем кадры и длительность
          const { frames, duration } = await createScreenshots(
            videoUrl,
            screenshotsCount,
            MAX_UPLOADED_VIDEO_DURATION, // передаём максимальную допустимую длительность
          );

          console.log("Video duration:", duration);

          // Сохраняем полученные данные в настройки видео
          updateVideoSetting("videoDuration", duration);
          updateVideoSetting("videoFrames", frames);
          updateVideoSetting("videoCutPoints", [
            videoSettings.videoCutPoints[0],
            Math.min(MAX_CUT_VIDEO_DURATION, duration),
          ]);
        } catch (err) {
          /**
           * Если произошла ошибка (превышение длительности или другая),
           * очищаем URL видео и отображаем сообщение об ошибке
           */
          const error = err as Error;
          updateVideoSetting("video", "");
          /**
           * Передаём ключ, приводя к never, так как t строго типизирован под ключи.
           * Используем interpolation {count: MAX_VIDEO_DURATION}, если нужно
           * отобразить допустимую длительность в сообщении.
           */
          toast.error(
            t(error.message as never, { count: MAX_UPLOADED_VIDEO_DURATION }),
          );
        }

        setLoading(false);
        sendGTM({ event: GE.UPLOAD_VIDEO__UPLOADBLOCK });
      },
    });

  return {
    maxFilesPhoto,
    getRootPhotoProps,
    getInputPhotoProps,
    getRootVideoProps,
    getInputVideoProps,
  };
};
