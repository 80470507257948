"use client";

import { BoxScrolled } from "@cloai/uikit";
import { isEmpty, map } from "lodash";
import { useTranslations } from "next-intl";
import { FC, memo, useMemo } from "react";

import { useUndressSettingsContext } from "@/providers";
import { cn } from "@/utils/cn";

/**
 * Свойства для компонента `Tab`
 */
type TTabProps = {
  index: number;
  text: string;
  activeIndex: number;
  onClick: (index: number) => () => void;
};

/**
 * Компонент вкладки (таба).
 * @param index - Индекс вкладки
 * @param text - Текст вкладки
 * @param activeIndex - Текущий активный индекс
 * @param onClick - Функция обработки клика по вкладке
 */
const Tab: FC<TTabProps> = memo(({ index, text, activeIndex, onClick }) => (
  <span
    className={cn(
      "cursor-pointer text-base font-medium whitespace-nowrap duration-200",
      "active:scale-95 active:duration-100",
      activeIndex === index && "text-primary",
    )}
    onClick={onClick(index)}
  >
    {text}
  </span>
));
Tab.displayName = "Tab";

/**
 * Компонент вкладок для управления настройками Undress.
 */
const Tabs: FC = () => {
  const t = useTranslations("HomePage.settingsPopup");
  const { activeTabIndex, handleTabClick, costumes, poses, sets } =
    useUndressSettingsContext();

  /**
   * Массив вкладок
   */
  const tabList = useMemo(() => {
    const result = [
      !isEmpty(costumes) && "settingsClothes",
      !isEmpty(poses) && "settingsPose",
      !isEmpty(sets) && "settingsSets",
    ].filter(Boolean) as never;

    return result;
  }, [costumes, poses, sets]);

  return (
    <BoxScrolled gap={16} row direction="horizontal" offsetOut={20}>
      {map(tabList, (tab, index) => (
        <Tab
          key={index}
          index={index}
          text={t(tab)}
          activeIndex={activeTabIndex}
          onClick={handleTabClick}
        />
      ))}
    </BoxScrolled>
  );
};

export default memo(Tabs);
