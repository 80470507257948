"use client";

import { FC, useMemo } from "react";

import { useHeadersContext, useUndressContext } from "@/providers";

import { UIWrapper } from "./UIWrapper";

/**
 * Компонент VideoBlockResult отвечает за отображение видеоблока с результатом генерации.
 */
export const VideoBlockResult: FC = () => {
  // Получаем host из useHeadersContext
  const { host } = useHeadersContext();

  // Деструктурируем нужные данные из useUndressContext
  const {
    clickFileId,
    isOpenCustomizeSettings,
    loading,
    onReset,
    undressings,
  } = useUndressContext();

  /**
   * Мемозируем текущий объект undress, основываясь на clickFileId.
   * Если undressings нет, возвращаем undefined.
   */
  const undress = useMemo(() => {
    if (!undressings) {
      return;
    }
    return undressings.find(({ id }) => id === clickFileId);
  }, [clickFileId, undressings]);

  // Извлекаем параметры видео из videoSettings
  const video = undress?.fileUrl;

  /**
   * Статус undress преобразуем в число для удобства проверки.
   * Используется для определения состояния интерфейса, например blur.
   */
  const status = useMemo(() => Number(undress?.status), [undress?.status]);

  // Если видео не задано, ничего не отображаем.
  if (!video) {
    return null;
  }

  return (
    <UIWrapper>
      <video
        loop
        autoPlay
        muted
        playsInline
        className="size-full object-contain"
      >
        <source src={video} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        <p>Your browser does not support embedded video.</p>
      </video>
    </UIWrapper>
    // <UploadedPhotoBlock
    //   src={video}
    //   type="video"
    //   blur={
    //     loading || [0, 2, 3].includes(status) // Если статус в одном из этих значений, применяем blur.
    //   }
    //   loader={<LoaderByHost host={host} />} // Отображаем лоадер при загрузке.
    //   processing={loading || [0].includes(status)}
    //   onReset={
    //     !undressings && !loading && !isOpenCustomizeSettings
    //       ? onReset
    //       : undefined
    //   }
    //   project="clothoff"
    // />
  );
};

VideoBlockResult.displayName = "VideoBlockResult";
