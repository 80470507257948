"use client";

import { Button } from "@cloai/uikit";
import { includes } from "lodash";
import { FC, PropsWithChildren } from "react";

import { InputUndressType } from "@/.gql/graphql";
import { useCompany } from "@/components/hooks/useCompany";
import { LoaderByHost } from "@/components/loader/LoaderByHost";
import {
  useABtestContext,
  useProfileContext,
  useUndressContext,
} from "@/providers";
import { cn } from "@/utils/cn";

import { PlateError } from "./InfoPlates";
import { PickImages } from "./PickImages";
import { Rating } from "./Rating";

export const UIWrapper: FC<PropsWithChildren> = ({ children }) => {
  const profile = useProfileContext(); // Контекст профиля, чтобы определить авторизован ли пользователь
  const { AB_anim_group_2 } = useABtestContext();
  const {
    clickUndress,
    clickFileId,
    clickUndressStatus,
    setClickFileId,
    setUndressingsFiles,
    undressingsFiles,
  } = useUndressContext(); // Контекст с данными о "раздевании" (`undress`)

  const { isInit, isLoading, isUndressing, isSuccess, isError, isBlur } =
    clickUndressStatus;

  const { companyLower } = useCompany();

  /**
   * Обработчик удаления изображения:
   * - удаляем текущее изображение из массива и устанавливаем выбранным последнее.
   */
  const onRemoveFile = () => {
    const newUndressingsFiles = undressingsFiles.filter(
      (img) => img.id !== clickFileId,
    );
    setUndressingsFiles(newUndressingsFiles);

    if (newUndressingsFiles.length > 0) {
      setClickFileId(newUndressingsFiles[newUndressingsFiles.length - 1].id);
    }
  };

  return (
    <div
      className={cn(
        "relative size-full overflow-hidden rounded-[inherit] border-2 border-card-border bg-card",
        "lg:border-0",
      )}
    >
      {children}

      {isError && <PlateError undress={clickUndress} />}

      <div className="absolute inset-0 flex items-center justify-center">
        <div className="absolute top-2 right-2 flex gap-2">
          {isInit && (
            <Button
              iconLeft="delete"
              color="regular"
              size="small"
              onClick={onRemoveFile}
            />
          )}

          {AB_anim_group_2 &&
            (isSuccess || isBlur) &&
            !clickUndress?.isGuest && <Rating />}
        </div>

        {(isLoading || isUndressing) && <LoaderByHost host={companyLower} />}

        {profile &&
          !includes(
            [InputUndressType.Animation, InputUndressType.Video],
            clickUndress?.undressType,
          ) && <PickImages />}
      </div>
    </div>
  );
};
