"use client";

import { ReactNode } from "react";

import { useMobileContext } from "@/providers";

type MirrorsLandingProps = {
  desktopView: ReactNode;
  mobileView: ReactNode;
};

export const MirrorsLanding = ({
  desktopView,
  mobileView,
}: MirrorsLandingProps) => {
  const { isMobiles } = useMobileContext();

  return isMobiles ? mobileView : desktopView;
};
