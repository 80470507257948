"use client";

import { PORTALS } from ".constants/portals";
import { Button, usePortalContext } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, SyntheticEvent } from "react";

import { InputUndressType } from "@/.gql/graphql";
import { useABtestContext, useUndressContext } from "@/providers";

import { DownloadFile } from "./DownloadFile";

export const DownloadBlock: FC = () => {
  const t = useTranslations("HomePage");
  const { clickUndress, clickUndressStatus, onReset, loading } =
    useUndressContext();
  const { openPortal } = usePortalContext();
  const { EmailTriggerFree, EmailTriggerVip } = useABtestContext();

  const { isEndAllUndress, isError } = clickUndressStatus;

  if (!isEndAllUndress) {
    return null;
  }

  /**
   * Функция `onReset` определяет, подписан ли пользователь на email-рассылку,
   * если нет - вызывает попап с предложением оставить своб почту.
   * Далее в любом случае  сбрасывает генерацию.
   */
  const handleReset = (event: SyntheticEvent) => {
    EmailTriggerFree && openPortal(PORTALS.SubscribesFREE);
    EmailTriggerVip && openPortal(PORTALS.SubscribesVIP);
    onReset(event);
  };

  return (
    <div className="flex gap-2">
      {isEndAllUndress && (
        <Button
          iconLeft="autorenew"
          text={t("newPhoto")}
          color={
            clickUndress?.undressType === InputUndressType.Animation
              ? "orange"
              : "regular"
          }
          onClick={handleReset}
          loading={loading}
          wide
        />
      )}
      <DownloadFile wide={!isEndAllUndress} disabled={isError} />
    </div>
  );
};
