"use client";

import { PATH_HOME } from ".constants/links";
import { Button } from "@cloai/uikit";
import { useRouter, usePathname } from "next/navigation";
import { useCookies } from "react-cookie";

import { setCookie } from "@/app/_actions/setCookie";
import { cn } from "@/utils/cn";

import { DAY_IN_SEC } from "../const";

type CtaButtonProps = {
  className?: string;
  text: string;
};

export const CtaButton = ({ text, className }: CtaButtonProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const [cookies] = useCookies(["rulesAccepted"]);

  const onTryFree = async () => {
    if (!cookies.rulesAccepted) {
      return;
    }

    await setCookie("app", "1", {
      maxAge: DAY_IN_SEC,
    });

    if (pathname !== PATH_HOME) {
      router.push(PATH_HOME);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <Button
      onClick={onTryFree}
      centerText
      text={text}
      className={cn("relative! z-2! rounded-lg!", className)}
      disabled={!cookies.rulesAccepted}
    />
  );
};
