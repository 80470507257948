"use client";

import { FC, useMemo } from "react";

import { UndressSettingsProvider, useUndressContext } from "@/providers";

import { CustomizeSettingsBlock } from "./CustomizeSettingsBlock";
import { MainSettingsBlock } from "./MainSettingsBlock";

/**
 * Основной компонент настроек Undress.
 * Оборачивает содержимое в `UndressSettingsProvider`, который управляет состоянием.
 */
export const UndressSettings: FC = () => {
  const { isActive, isOpenCustomizeSettings } = useUndressContext();

  const content = useMemo(() => {
    switch (true) {
      case !isActive:
        return <MainSettingsBlock />;
      case isOpenCustomizeSettings:
        return <CustomizeSettingsBlock />;
      default:
        return null;
    }
  }, [isActive, isOpenCustomizeSettings]);

  return <UndressSettingsProvider>{content}</UndressSettingsProvider>;
};
