"use client";

import { AddPickFace, PickFace, PickFaceSet } from "@cloai/uikit";
import { FC } from "react";

import { useUndressContext } from "@/providers";

import { useDropzoneSettings } from "../utils/useDropzoneSettings";

export const PickImages: FC = () => {
  const {
    clickFileId,
    loading,
    reUseFaceObj,
    selectedVipSettings,
    setClickFileId,
    undressings,
    undressingsFiles: _undressingsFiles,
  } = useUndressContext(); // Контекст с данными о "раздевании" (`undress`)

  // Если undressings отсутствуют, используем _undressingsFiles
  const undressingsFiles = undressings ?? _undressingsFiles ?? [];

  // Настройки дропзоны для фото
  const { maxFilesPhoto, getRootPhotoProps, getInputPhotoProps } =
    useDropzoneSettings();

  return (
    <PickFaceSet bottom>
      {!loading &&
        !undressings?.length &&
        undressingsFiles.length < maxFilesPhoto &&
        !reUseFaceObj && (
          <AddPickFace {...getRootPhotoProps({})}>
            <input {...getInputPhotoProps()} />
          </AddPickFace>
        )}
      {undressingsFiles.map(
        (undressingsFile: {
          id: string;
          fileUrl?: string | null;
          status?: number;
          error?: string | null;
        }) => {
          if (
            !undressingsFile.fileUrl ||
            typeof undressingsFile.fileUrl !== "string"
          ) {
            return null;
          }

          // Определяем статус `PickFace`:
          // - `loading` при `status=0`,
          // - `done` при `status=1`,
          // - `error` при наличии `error`,
          // - `tune` при `selectedVipSettings`,
          // - `ready` по умолчанию.
          const pickFaceStatus =
            undressingsFile?.status === 0
              ? "loading"
              : undressingsFile?.status === 1
                ? "done"
                : undressingsFile.error
                  ? "error"
                  : selectedVipSettings
                    ? "tune"
                    : "ready";

          return (
            <PickFace
              key={undressingsFile.id}
              image={undressingsFile.fileUrl}
              active={clickFileId === undressingsFile.id}
              onClick={() => setClickFileId(undressingsFile.id)}
              status={pickFaceStatus}
            />
          );
        },
      )}
    </PickFaceSet>
  );
};
