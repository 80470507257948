"use client";

import { useTranslations } from "next-intl";
import { FC } from "react";

import { useUndressContext } from "@/providers";
import { cn } from "@/utils/cn";

import { CustomizeSettingsList } from "./CustomizeSettingsList";

export const CustomizeSettingsBlock: FC = () => {
  const t = useTranslations("HomePage.settingsPopup");

  const { closeCustomizeSettings } = useUndressContext();

  return (
    <div
      className={cn(
        "-mx-container flex flex-col gap-indent overflow-hidden px-container",
        "lg:mx-0 lg:px-0",
      )}
    >
      <div
        className="flex w-fit cursor-pointer items-center gap-3 text-lg font-bold"
        onClick={closeCustomizeSettings}
      >
        <span className="material-icons" data-icon="arrow_back" />
        {t("customizeSettings")}
      </div>

      <CustomizeSettingsList />
    </div>
  );
};
