"use client";

import { motion } from "framer-motion";
import { random } from "lodash";
import { useState, FC, useRef, RefObject } from "react";
import { useInterval, useIntersection } from "react-use";

/**
 * Props для компонента AnimatedCounter
 * @typedef {Object} AnimatedCounterProps
 * @property {number} count - Начальное значение счетчика
 */

/**
 * Компонент анимированного счетчика просмотров
 * Отображает число с анимацией изменения цифр, увеличивающееся на случайное значение
 * каждые 1-3 секунды, когда компонент находится в области просмотра
 *
 * @component
 * @param {AnimatedCounterProps} props - Свойства компонента
 * @returns {JSX.Element} Анимированный счетчик просмотров
 */
export const AnimatedCounter: FC<{ count: number }> = ({ count }) => {
  const [viewsCount, setViewsCount] = useState(count);
  const [delay, setDelay] = useState(random(1000, 3000));
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Настройка IntersectionObserver для отслеживания видимости компонента
   * @fires useIntersection
   */
  const intersection = useIntersection(containerRef as RefObject<HTMLElement>, {
    root: null, // Использует viewport как область наблюдения
    threshold: 0.1, // Срабатывает при видимости 10% элемента
  });
  const isVisible = !!intersection?.isIntersecting;

  /**
   * Интервал обновления счетчика, активен только когда компонент виден
   * @fires useInterval
   */
  useInterval(
    () => {
      const randomIncrement = random(1, 7);
      setViewsCount((prevCount) => prevCount + randomIncrement);
      setDelay(random(1000, 3000));
    },
    isVisible ? delay : null, // null отключает интервал
  );

  /**
   * Преобразование числа в массив анимированных символов
   *  */
  const digits = viewsCount
    .toLocaleString()
    .split("")
    .map((char, index) => (
      <motion.span
        key={`${char}-${index}`}
        className="inline-block"
        initial={{ y: 10, opacity: 0 }}
        animate={isVisible ? { y: 0, opacity: 1 } : { y: 20, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          duration: 0.2,
        }}
      >
        {char}
      </motion.span>
    ));

  return (
    <span>
      <motion.span ref={containerRef} className="tabular-nums" layout>
        {digits}
      </motion.span>
    </span>
  );
};
