"use client";

import { motion, useAnimation } from "framer-motion";
import { useState, useEffect, useRef, RefObject } from "react";
import { useIntersection } from "react-use";

/**
 * Компонент кольца с анимированным прогрессом и числом
 * @component
 * @returns {JSX.Element}
 */
export const ProgressRing = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(containerRef as RefObject<HTMLElement>, {
    root: null,
    threshold: 1, // Срабатывает, когда 100% элемента видно
  });
  const isVisible = !!intersection?.isIntersecting;

  const [count, setCount] = useState(0);
  const controls = useAnimation();

  // Анимация при появлении в зоне видимости
  useEffect(() => {
    if (isVisible) {
      // Запуск анимации пути и числа
      controls.start({ pathLength: 1 });
      if (count === 90) {
        return;
      }
      let start = 0;
      const end = 90;
      const duration = 2000; // 2 секунды
      const increment = end / (duration / 16); // ~60 кадров в секунду

      const animateCount = () => {
        start += increment;
        if (start >= end) {
          setCount(end);
          return;
        }
        setCount(Math.floor(start));
        requestAnimationFrame(animateCount);
      };

      requestAnimationFrame(animateCount);
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, controls]);

  return (
    <div
      ref={containerRef}
      className="relative flex h-14 w-14 shrink-0 items-center justify-center rounded-full"
    >
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0"
      >
        <circle
          cx="28"
          cy="28"
          r="26"
          stroke="currentColor"
          strokeWidth="4"
          className="text-secondary"
        />
        <motion.path
          d="M28 2C34.1907 2 40.1782 4.20892 44.8856 8.22945C49.5931 12.25 52.7115 17.8182 53.6799 23.9327C54.6483 30.0472 53.4033 36.3065 50.1686 41.585C46.934 46.8634 41.9221 50.8144 36.0344 52.7275C30.1468 54.6405 23.7697 54.3899 18.0502 52.0209C12.3308 49.6518 7.64434 45.3197 4.83383 39.8038C2.02332 34.2878 1.2732 27.9501 2.71838 21.9304C4.16357 15.9108 7.70921 10.6044 12.7176 6.96556"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          className="text-primary"
          initial={{ pathLength: 0 }}
          animate={controls}
          transition={{ duration: 1.5, ease: "easeInOut" }}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.span
          className="text-sm font-extrabold text-primary"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          {count}%
        </motion.span>
      </div>
    </div>
  );
};
