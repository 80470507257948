"use client";

import { FC } from "react";

import { GenderSwitch } from "./GenderSwitch";
import { MainSettingsList } from "./MainSettingsList";
import Tabs from "./Tabs";
import { UIListWrapper } from "./UIListWrapper";

export const MainSettingsBlock: FC = () => (
  <UIListWrapper>
    {/* Верхняя панель с табами и переключателем пола */}
    <div className="flex items-center justify-between gap-5">
      <Tabs />
      <GenderSwitch />
    </div>

    {/* Основной список настроек */}
    <MainSettingsList />
  </UIListWrapper>
);
