"use client";

import { FC, HTMLAttributes, memo, PropsWithChildren } from "react";

import { cn } from "@/utils/cn";

export const UIListWrapper: FC<
  HTMLAttributes<HTMLDivElement> & PropsWithChildren
> = ({ className, children, ...props }) => (
  <div
    className={cn(
      "-mx-container flex flex-col gap-indent overflow-hidden px-container pb-indent",
      "lg:m-0 lg:h-95 lg:rounded-2xl lg:p-4 lg:inset-ring-2 lg:inset-ring-regular",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export default memo(UIListWrapper);
